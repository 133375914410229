
@keyframes arrow_move_down {
    0% {bottom:1px;}
    100% {bottom:-9px;}
}

.page .banner.nav {
	// margin-top:-5px;
	display:block;
	position: absolute;
	left:0;
	right:0;
	bottom:0;
	min-height:84px;
	background:transparentize(#2e2b26, 0.1);
	z-index:10;
	@include brk($m) { margin-top:0px; }
	.couleur-overlay {
		@extend .abs;
	}

	nav#nav_child {
		position: relative;
		>ul>li>a, >ul>li:not(.current-menu-parent)>ul>li, >ul>li:not(.current-menu-parent)>ul {
	        display: none;
	    }


		// LEVEL 2  -----------------
		>ul>li {
			transition: background 2s;
			display:none;@extend .cache-left, .cache-right}
		>ul>li {
			display:block!important;
			animation: fade 1s;
			// >a {display:none;}
			>ul>li {
				display: inline-block;
				position: relative;
				float: left;
				@include brk($m) { 
					&, &>a {width:100%; }
					&:first-child a {padding-top:2px;}
					&:last-child a {padding-bottom:2px;}
				}
				// a {background:rgba(0,0,0,0.4)}
				&:after {
					content:"";
					position: absolute;
					bottom:0px;
					left:0;
					height:10px;
					width:100%;
					text-align: center;
					display: none;
					background: darken(#151311, 10%);
					@include brk($m) { display:none }
				}
				
				&.active, &.current_page_item, &:hover {
					background:#2c2622 !important;
					a {background:transparent}
					&:after {
						display: inline-block;
					}
				}
				&.active .arrow-down {
					display: inline-block;
					transition: top 0.3s;
					// animation: arrow_move_down 1s;
					opacity:1;
					top:9px;
				}
				a {
					height:84px;
					padding:35px 23px;
					color:#fff;
					letter-spacing: 1px;
					// animation: fade 2s;
					font-size: 11px;
					text-transform: uppercase;
					font-weight: $bold;
					@include brk($m)  { 
						height: auto;
						border-top: 1px solid transparentize(#fff, 0.9);
						padding:18px $padding-mobile; 
					}
				}
			}
		}
		>ul>li>ul>li>ul {
			display: none;
		}


		>ul>li>ul>li.active {
			// display:block!important;
			// animation: fade 2s;
			// >a {display:none;}


			// LEVEL 3  -----------------

			>ul>li {
				display: inline-block;
				position: relative;
				@include brk($m) { 
					&, &>a {width:100%; }
					&:first-child a {padding-top:2px;}
					&:last-child a {padding-bottom:2px;}
				}
				a:after {
					content:"";
					display: inline-block;
					position: absolute;
					bottom:-9px;
					left:0;
					height:9px;
					width:100%;
					opacity: 0;
					background: darken($touche_de_couleur, 10%);
					@include brk($m) { display:none }
				}
				&.active, &:hover {
					background:$touche_de_couleur !important;
					a:after {
						transition: bottom 0.3s, opacity 0.4s;
						opacity: 1;
						bottom:0px;
					}
				}
				a {
					height:84px;
					padding:30px 30px;
					color:#fff;
					font-size: 14px;
					@include brk($m)  { 
						height: auto;
						border-top: 1px solid transparentize(#fff, 0.9);
						padding:18px $padding-mobile; 
					}
				}
			}
		}

	}
}




















.page .banner.nav.level3 {
	position: relative;
	background:#ede4d6;
	min-height: auto;
	display:inline-block;
}

nav#nav_child3 {
	position: relative;
	>ul>li>a, 
	>ul>li:not(.current-menu-parent)>ul>li, 
	>ul>li:not(.current-menu-parent)>ul {
        display: none;
    }

    >ul>li>ul>li>a {
		display: none;
    }

    >ul>li>ul>li:not(.current-menu-item) {
    	display: none!important;
    }
	>ul>li>ul>li.current-menu-item {
		display:block!important;
		animation: fade 1s;
		// >a {display:none;}
		>ul>li {
			display: inline-block;
			position: relative;
			float: left;
			@include brk($m) { 
				&, &>a {width:100%; }
				&:first-child a {padding-top:2px;}
				&:last-child a {padding-bottom:2px;}
			}
			// a {background:rgba(0,0,0,0.4)}
			&:after {
				content: "";
				display: inline-block;
				position: absolute;
				bottom:0px;
				left:0;
				height:10px;
				width:100%;
				text-align: center;
				display:none;
				background: darken(#ded4c4, 0%);
			}
			
			&.active, &.current_page_item, &:hover {
				background:#e5dbcc !important;
				a {background:transparent}
				&:after {
					display: inline-block;
				}
			}
			&:first-child {
				background:#e5dbcc!important;
				&:after {
					display: inline-block;
				}
			}
			a {
				height:84px;
				padding:34px 23px;
				color:#2e2b26;
				letter-spacing: 1px;
				text-transform: uppercase;
				// animation: fade 2s;
				font-size: 11px;
				font-weight: $bold;
				@include brk($m)  { 
					height: auto;
					border-top: 1px solid transparentize(#fff, 0.9);
					padding:18px $padding-mobile; 
				}
			}
		}
	}

}