



#header_behind {
    height:$header-height;
    background: #fff;
    position: relative;
    @include brk($t) {
        height:$header-height-tablet;
        background:none;
        display: none;
    }
    @include brk($m) {height:$header-height-mobile;}
    &.page {
        background: #fff;
    }
}


#header {
    height:$header-height;
    position:fixed;
    top:0px;
    left:0px; 
    z-index: 99;
    background:$white;
    // border-bottom: 1px solid rgba(255,255,255,0.15);
    box-shadow: 0px 3px 40px 0px rgba(0, 0, 0, 0.35);
    @include brk($t) {
        height:$header-height-tablet;
        background:transparentize(#2e2b26, 0.2);
    }
    @include brk($m) {height:$header-height-mobile;}
    &.page {
        background:none;
    }
    .banner-content {
        height: $header-height;
        margin: 0 auto;
        text-align: center;
        @include brk($header-getLarge) {
            margin:0; 
            padding:0 30px 0 30px;
            // transition: 0.5s;
        }
        @include brk($t) {height:$header-height-tablet;}
        @include brk($m) {height:$header-height-mobile;}
    }
}







#header .logo-container {
    display: inline-block;
    height:100%;
    width:194px;
    margin:0 0px;
    position: relative;
    padding-top: 16px;
    background:#fff;
    &:hover {
        // box-shadow: 0px 5px 43px 0px rgba(0, 0, 0, 0.26);
    }
    @include brk($t) {
        height: 111px;
        width:170px;
        padding-top: 7px;
    }
    @include brk($m) {
        height: 90px;
        width:150px;
        padding-top: 3px;
    }
    .logo-image {
        background-image: url('../assets/images/logo.png');
        // background-image: url('../images/logo.svg');
        background-size:contain;
        // animation: fade 1.5s;
        display: block;
        margin:0px auto 0;
        position: relative;
        width:160px;
        height:100%;
        .st0{fill:none;}
        .st1{fill:#00A9B9;}
        .st2{fill:#F18700;}
        @include brk($t) {
            top:0px;
            left:19px;
            height:83px;
            width:166px;
        }
        @include brk($m) {
            height:72px;
            left:19px;
        }
    }
    .cache-blanc {
        height:40px;
        width:100%;
        position: absolute;
        bottom:-39px;
        left:0;
        right:0;
        background-size: contain;
        background-position:50% 0%;
        background-image: url('../assets/images/cache-blanc-logo.png');
    }
}







#header .btn-container {
    position: absolute;
    height: 58px;
    bottom:-58px;
    right:0;
    width:100%;
    @include brk($t){display:none;}
    .btn-01 {
        float: right;
        margin-left: 5px!important;
        box-shadow: inset 0px 33px 33px -30px rgba(0, 0, 0, 0.4);
        transition: background 1s;
        @include brk($t){display:none;}
        p {
            font-size: 14px;
            letter-spacing: 2px;
            text-transform: uppercase;
        }
        &.infos {
            background:#3d392f;
            height:83px;
            p {
                font-weight: $light;
                font-size: 14px;
                color:#968d78;
                letter-spacing: 1px;
                padding-top:17px;
                padding-right:105px;
                position: relative;
                text-align: left;
                span {
                    display: block;
                    font-weight: $bold;
                    font-size: 19px;
                    color:#fff;
                }
            }
            .icon {
                background:transparentize(#fff, 0.5);
                height:42px;
                width:42px;
                top:21px;
                right:17px;
                border-radius:50%;
                border-left:0;
                transition: background 1s;
                &:before {
                    height:47px;
                    width:1px;
                    content:"";
                    display: inline-block;
                    position: absolute;
                    left:-21px;
                    top:-2px;
                    background: transparentize(#fff, 0.7);
                }
            }
            svg {
                transform: scale(0.4);
                .st0 {
                    fill: #3d392f;
                }
            }
            &:hover {
                background:darken(#3d392f, 9%);
                .icon {
                    background:transparentize(#fff, 0);
                }
            }
        }
    }
}














.nav_desktop {
    display:inline-block;
    height:100%;
    min-width: 100px;
    @include brk($t) {display:none;}
    &.left {
        >ul>li:nth-child(n + 4) {display:none;}
    }
    &.right {
        margin-right: 27px;
        >ul>li:nth-child(1),
        >ul>li:nth-child(2),
        >ul>li:nth-child(3) {display:none;}
        >ul>li:nth-child(4):before {display:none;}
    }
    >ul {
        list-style-type:none;
        height:100%;
        user-select: none;
    }
    >ul>li {
        float: left;
        display:inline-block;
        height:$header-height;
        line-height:$header-height;
        margin-left:-5px;
        user-select: none;
        position: relative;
        &.selected, &.current-menu-item, &.current_page_ancestor {
        }
        &:hover, &.current-menu-item, &.current_page_ancestor {
            >a {color:transparentize(#4f4630,0);}
        }
    }
    >ul>li>a {
        display: inline-block;
        padding: 27px 28px 20px;
        // animation: fade 1.5s;
        font-size: 11.5px;
        letter-spacing: 1px;
        color: transparentize(#4f4630,0.1);
        font-weight: $bold;
        text-transform: uppercase;
        height:100%;
        user-select: none;
        &:hover {color:transparentize(#4f4630,0);} 
    }




    // HOME ICON ---------------------------------

    // >ul>li:first-child {
    //     position:relative; width:57px;
    //     a::after {
    //         content:"";
    //         width:21px;
    //         height: 21px;
    //         display: inline-block;
    //         position:absolute;
    //         top:30px;
    //         left:18px;
    //         cursor:pointer!important;
    //         background:url('../assets/images/icon-home-2.svg');
    //     }
    //     a {color: rgba(0,0,0,0);}
    // }



    // SOULIGNÉ ----------------------- 

    >ul>li:not(.btn)>a:before {
        content:"";
        width:0px;
        opacity: 0;
        transition: width 0.2s, opacity 0.5s, background 0.3s;
        height:1px;
        position: absolute;
        display: inline-block;
        background:transparentize($touche_de_couleur, 0.1);
        left:28px;
        bottom:51px;
    }
    >ul>li:not(.btn):hover >a:before,
    >ul>li:not(.btn).current-menu-ancestor >a:before,
    >ul>li:not(.btn).current-menu-item >a:before {
        opacity: 1;
        width:calc(100% - 58px);
    }



    // SEPARATION VERTICALE -----------------

    >ul>li:before {
        content:"";
        width:1px;
        height:43px;
        position: absolute;
        display: inline-block;
        background:transparentize(#4f4630, 0.9);
        top:46px;
        left:0px;
    }
    >ul>li:first-child:before {display:none;}



    // FLECHE PARENT -----------------

    // >ul>li.menu-item-has-children {
    //     position: relative;
    //     a {padding-right:30px;}
    //     a:after {
    //         content:"";
    //         width:7px;
    //         height:8px;
    //         display: inline-block;
    //         position:absolute;
    //         top:38px;
    //         right:17px;
    //         cursor:pointer!important;
    //         background:url('../assets/images/parent-arrow.svg');
    //     }
    // }


}






//  LEVEL 2   ------------------------------------


.nav_desktop >ul>li {
    &:hover ul {
        display:block;
    }
    >ul {
        position:absolute;
        left:0px;
        top:$header-height;
        margin-left: -22px;
        margin-top: -6px;
        display: none!important;
        z-index: 10;
        padding: 0 60px 60px 40px;
    }
    >ul>li {
        background:#fff;
        display:inline-block;
        float:left;
        height:100%;
        border-top: 1px solid transparentize(#000, 0.9);
        transition: border 1s;
        // &:last-child {padding-right:0}
        // &.selected, &.current-menu-item a, &:hover a{border-top:3px solid $white;}
        &:hover {
            background:#eee;
            &, & + li {
                transition: border 0.5s;
                border-top: 1px solid transparentize(#000, 1);
            }
            >a {
                color:#173c73;
            }
        }
        &.current-menu-item>a {
            color:#173c73;
            font-weight: $bold;
        }
        >a {
            height:100%;
            width:290px;
            padding: 30px 0px;
            font-size:14px;
            letter-spacing: 1.5px;
            border-top:3px solid #333333;
        }
    }
    // &.current_page_ancestor>ul {display:inline-block;}
    >ul>li.menu-item-has-children.current-menu-item ul{display:block}
    >ul>li>ul {
        display: block;
        position:absolute;
        top:100px;
        left:0px;
        height:100px;
        display:none;
        background:$level3; width:40000px;
        >li {
            display:inline-block;
            float:left;
            height:100%;
            &:first-child a {padding-left:0}
            a {
                padding-top:42px;
                display:inline-block;
                font-size:13px;
                font-weight: 700;
                color:#333333;
                &:hover {color:$dark-grey;}
            }
        }
    }
}









#header .social-icons {
    // background:green;
    height:100%;
    position: absolute;
    top:0;
    right:48px;
    right:0;
    // animation: fade 2.5s;
    // margin-right: -12px;
    @include brk ($tablet) {display:none;}
    .icon-container {    
        float: left;
        height:$header-height;
        line-height:$header-height;
        transition: height 1s, line-height 1s;
        position: relative;
        text-align: center;
        padding: 0 1px;
        .icon {
            width: 37px;
            height: 37px;
            @extend .bloc;
            margin-bottom: 4px;
            svg {
                transform: scale(0.8);
                transition: transform 0.5s;
            }
            .st0 {
                fill:#c9c9c9;
                transition: fill 0.3s;
            }
            .st1 {
                fill:#fff;
            }
        }
        &:hover {
            .icon svg { transform: scale(1);}
            .icon .st0 {fill: $touche_de_couleur2}
            // .icon .st0 {fill: rgba(255,255,255,1)}
        }
        &.search {
            cursor: pointer;
            .icon {
                width: 30px;
                height: 30px;
                transition: transform 0.5s, background 0.6s;
                background:$touche_de_couleur;
                border-radius:50%;
                svg {transform:scale(0.55)}
                path {fill:#fff;}
            }
        }
        &.search:hover {
            .icon.search {
                transform: scale(1.2);
                svg { transform: scale(0.5);}
            }
        }
        &.facebook:hover {
            // .icon .st0 {fill: #3B5998}
        }
    }
}

















// WPML -----------------------------------------------------



#header .wpml-ls-legacy-dropdown {
    height: $header-height;
    display: inline-block;
    display: none;
    position: absolute;
    top:0;
    right:0;
    @include breakpoint ($menu-off) {
        display: none;
    }
    >ul {
        height:$header-height;
    }
    a {
        font-size: 14px;
        font-weight: $bold;
        color: #373839;
        font-family: $family2;
    }
    >ul>li>a {
        // animation: fade 2s;
        height:$header-height;
        padding:  53px 16px 10px 12px;
        text-transform: capitalize;
        &:focus { border:none; }
        span {
            position: relative;
            &::after {
                content:"";
                width:8px;
                height:8px;
                position:absolute;
                top:9px;
                // animation: fade 1s;
                right:-14px;
                background-size:contain!important;
                background: url('../assets/images/wpml_down.svg')
            }
        }
    }
    ul:hover, ul:hover ul {
        visibility:visible !important;
    }
    >ul>li>ul {
        visibility:hidden;
        position:absolute;
        // height:100%;
        top:88px;
        left:-12px;
        z-index: 100;
        padding: 0 20px 20px;
    }
    >ul>li>ul>li {
        height: 40px; 
        width: 40px;
        background:#eeeeee;
        border-radius:50%;
        margin-bottom: 9px;
        &:hover {background:$white;}
    }
    >ul>li>ul>li>a {
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.15);
        width: 100%;
        height:100%;
        text-align: center;
        padding-top:8px;
        border-radius:50%;
    }
}