




//  FORMAT CLASS
// ______________________________________




$format__p__font_size: 14px;
$format__p__font_weight: $light;
$format__p__color: darken(#625538, 0%);
$format__p__letter_spacing: 0.3px;
$format__p__word_spacing: 0px;
$format__p__line_height: 2;
$format__p__padding_top: 5px;
$format__p__padding_bottom: 20px;

$format__a__font_weight: 	$normal;
$format__a__color: 			#4a4337;

$format__h__font_weight : $regular;
$format__h__color : #625538;
$format__h__letter_spacing : 1.5;
$format__h__font_family: $family2;
$format__h__text_transform: uppercase;
$format__h__padding_top: 0px;
$format__h__padding_bottom: 25px;



img.alignleft {
	float: left;
	margin: 5px 40px 20px 0;
}
img.alignright {
	float: right;
	margin: 5px 0 20px 40px;
}
img.aligncenter {
	margin: 20px auto 15px;
	display: block;
}
p, li, a, h1, h2, h3, h4, h5, h6 {
	color:$grey;
}
a {
	font-size: $format__p__font_size;
	line-height: $format__p__line_height;
	color: $format__a__color;
	letter-spacing: $format__p__letter_spacing;
	word-spacing: $format__p__word_spacing;
	font-weight: $format__a__font_weight;
	// font-style:italic;
	margin-left: 2px;
	margin-right: 2px;
	position: relative;
	&:after {
		content:"";
		display: inline-block;
		border-bottom: 2px solid rgba(0,0,0,0.5);
		position: absolute;
		height:1px;
		left:0;
		bottom:3px;
		width:0;
		opacity: 0;
		transition: width 0.2s, opacity 0.8s;
	}
	&:hover:after {
		color:#000;
		width:100%;
		opacity:1;
	}
}
p {
	font-size: $format__p__font_size;
	line-height: $format__p__line_height;
	color: $format__p__color;
	letter-spacing: $format__p__letter_spacing;
	word-spacing: $format__p__word_spacing;
	font-weight: $format__p__font_weight;
	padding-top: $format__p__padding_top;
	padding-bottom: $format__p__padding_bottom;
	@include brk($m) {
		text-align: left!important;
	}
}
blockquote p {
	color:#434343;
	text-align:center;
	font-size:23px;
	font-weight:300;
	letter-spacing:1.2px;
	line-height:1.8;
	padding-top:20px;
	padding-bottom:35px;
}
h2, h3, h4 {
	font-weight: $format__h__font_weight;
	color:$format__h__color;
	line-height:1.6;
	padding-top:$format__h__padding_top;
	padding-bottom:$format__h__padding_bottom;
	letter-spacing: $format__h__letter_spacing;
	font-family: $format__h__font_family;
	text-transform: $format__h__text_transform;
}
li {
	font-size: $format__p__font_size;
	font-weight: $format__p__font_weight;
	color: $format__p__color;
	line-height:1.9;
	list-style-type:none;
	// display: table-row;
	margin-bottom:10px;
	vertical-align: top;
	padding-left:15px;
	position: relative;
	letter-spacing: $format__p__letter_spacing;
	word-spacing: $format__p__word_spacing;
	// &::after {content:"";display:table-row;height:10px;}
}
ul, ol {
	padding-top:10px;
	padding-bottom:20px;
	display:table;
}
ul {
    li:before {
        content: '›';
        color: $format__p__color;
        font-weight: $bold;
        position: absolute;
		left: 0px;
		top: 0px;
    }
}
ol {
    // list-style-position: inside;
    margin-left:20px;
    counter-reset: foo;
    li {
        counter-increment: foo;
        padding-left:17px;
        &::before {
        	content: counter(foo) ".";
        	position: absolute;
			left: 2px;
			top: 0px;
        }
    }
}