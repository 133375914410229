$ft: $t + 0px;
$fm: $m + 0px;



footer {
    overflow-y: hidden;
    background: $footer-color;
    #footer_top {
        background:#2e2b26;
        padding-top:45px;
        padding-bottom:30px;
        @include brk($ft) {
            padding-top:30px;
        }
        @include brk($fm) {
            padding-top:25px;
        }
    }
    #footer_bottom {
        padding-top: 8px;
        padding-bottom: 5px;
        @include brk($fm) {
            padding-top: 18px;
            padding-bottom: 18px;
        }
    }
    .banner-content {
        @include brk($ft) {
            padding-left: 10%;
            padding-right: 10%;
        }
        @include brk($fm) {
            padding-left: 6%;
            padding-right: 6%;
        }
    }
    .titre-section {
        color:$touche_de_couleur;
        font-size: 20px;
        font-weight: $bold;
        letter-spacing: 0.5px;
        margin-bottom: 15px;
        user-select:none;
    }
}






// LOGO ---------------------------------------

footer .logo-banner {
    text-align: center;
    padding-bottom:35px;
    .logo-container {
        height:100px;
        width: 200px;
        @include brk($ft) {
            margin: 0 auto;
            float: none;
        }
        .logo-image {
            height:100%;
            width:100%;
            position: relative;
            background-size: contain;
            background-position:50% 50%;
            background-image: url('../assets/images/logo-blanc.png');
            .st0{clip-path:url(#SVGID_2_);fill:#FFFFFF;}
            .st1{clip-path:url(#SVGID_2_);fill:#75B558;}
        }
    }
}








// COORDONNES -----------------------------------------

footer .bloc-coordonnees {
    width:100%;
    text-align: center;
    .col {
        display: inline-block;
        width: auto;
        float: none;
        @include brk($ft) { width: 100%; }
    }
    p, a, span {
        font-size: 15px;
        display: inline;
        color:transparentize(#fff, 0.5);
        line-height: 2.1;
        font-weight: $light;
        font-family: $family2;
        @include brk($fm) { 
            font-size: 13.5px;
            letter-spacing: 0;
        }
    }
    .col1 {
        margin-bottom: 5px;
        .rue, .ville, .pays {
            color:$touche_de_couleur;
        }
    }
    span {
        // display:block;
        font-weight: $bold;
        a {padding-left:5px;}
    }
    a {position: relative;}
    a:after {
        content:"";
        display: inline-block;
        position: absolute;
        bottom:-2px;
        left:0;
        height:2px;
        width:0;
        opacity: 0;
        transition: opacity 0.5s, width 0.2s;
        background: lighten($touche_de_couleur, 10%);
    }
    a:hover {
        &:after {
            width:100%;
            opacity: 1;
        }
    }
    @include brk($fm) { 
        .col1 {
            margin-bottom: 0px;
        }
        .email {
            display: block;
        }
    }
}



// MENU ---------------------------------------------

footer .menu-footer {
    float: left;
    .titre-section {
        span {
            color:$touche_de_couleur2;
        }
    }
    >ul>li {
        &:first-child {
            a { border-top:none; }
        }
        &:hover{
            a { color:transparentize(#fff, 0); }
        }
    }
    >ul>li>a {
        color:transparentize(#fff, 0.1);
        font-size: 16px;
        padding:6px 0px;
        width:200px;
        border-top: 1px solid transparentize(#fff, 0.9);
        &:before {
            content:"›";
            margin-right: 5px;
        }
    }
    >ul>li>ul {display:none;}
}







// COPYRIGHT LINE ----------------------------------

footer .copyright-line {
    display:inline-block;
    text-align:center;
    width:100%;
    p, a, span {
        color:rgba(255,255,255,0.5);
        // text-transform: uppercase;
        font-size: 11px;
        letter-spacing:0.6px;
        transition: color 0.6s;
    }
    a {
        padding: 15px 15px;
        text-decoration: none;
        position: relative;
        cursor:pointer;
        // text-transform: uppercase;
        @include brk($fm) {
            padding: 4px 15px;
        }
        &:hover {color:#eee;}
        &:first-child {
            border-bottom:none;
            &:before {display:none;}
        }
        &:before {
            content:"";
            display: inline-block;
            position: absolute;
            top:14px;
            left:-4px;
            width:1px;
            height:15px;
            background:rgba(255,255,255,0.15);
            @include brk($fm){display:none;}
        }
    }
    p {
        display:inline;
    }
    @include brk($fm) {
        .copyright {display: block}
    }
}




