




#hamburger_title {
    display: none;
    position: absolute;
    top: 0;
    right: -8px;
    height:$header-height;
    align-items:center;
    margin-right: ($header-height);
    font-size: 8px;
    font-weight: $bold;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    line-height: 1;
    padding-top: 2px;
    transition: opacity 0.5s;
    width: 45px;
    &.page {
        color:#fff;
    }
    @include brk($t) {
        display:flex;
        // display: none;
        height:$header-height-tablet;
        margin-right: ($header-height-tablet);
    }
    @include brk($m) {
        display:flex;
        // display: none;
        height:$header-height-mobile;
        margin-right: ($header-height-mobile);
    }

    span {
        font-weight: $bold;
        font-size: 14px;
        letter-spacing:0.5px;
    }
}









#hamburger_container {
    position: fixed;
    // background: $touche1;
    animation: fade 1s;
    width:$header-height; 
    height:$header-height;
    z-index: 1010;
    right:0;
    top: 0;
    cursor:pointer;
    display: none;
    &.page {
        
    }
    @include brk($t) {
        display:inline-block;
        margin-right: 0;
        width:$header-height-tablet; 
        height:$header-height-tablet;
    }
    @include brk($m) {
        width:$header-height-mobile; 
        height:$header-height-mobile;
    }
    &.is-active {
        background:#2e2b26;
        path { fill:#fff !important; }
        #hamburger_title {
            opacity: 0;
        }
    }

    button {
        height: 100%;
        width: 100%;        
        position: absolute;
        top: 0;
        left: 0;
        transition: transform 0.4s;
        border: none;
        outline: none;
        display: flex;
        align-items:center;
        justify-content: center;
        @include brk($tp) {
        }
        @include brk($m) {
        }

        svg path { fill:#fff; }
        &.page {
            svg path { fill:#fff; }
        }
    }




    button svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        position: relative;
        .path-container {
            transition: transform 0.65s ease, opacity 0.25s ease;
            @include brk($m) {
                transition: transform 0.5s ease, opacity 0.25s ease;
            }
            transform: translate3d(0, 0, 0);
            &.top {
                transform-origin: 16% 32%;
                &.edge { transform-origin: 12% 90%;}
            }
            &.middle {transform-origin: 25% 60%;}
            &.bottom {
                transform-origin: 14% 92%;
                // transform: translate3d(-8px,-0px,0);
                // transform: translate3d(8px,-0px,0);
                &.edge {transform-origin: 15% 50%;}                
            }
        }
    }


    &.is-active button {
        svg {

            .path-container {
                &.top {                    
                    transform: rotate(45deg) scale(1.25, 1);                    
                }
                &.middle {
                    opacity: 0;
                    transform: scale(0.5); 
                }
                &.bottom {
                    transform: rotate(-45deg) scale(1.25, 1);                    
                }
            }
        }
    }


    &.back-arrow button {
        svg {
            transform: rotate(180deg) translate3d(5px,0,0);
            .path-container {
                transition: transform 0.2s ease, opacity 0.25s ease;
                &.top {                    
                    transform: rotate(45deg) scale(0.6, 1);                    
                }
                &.bottom {
                    transform: rotate(-45deg) scale(0.60, 1);                    
                }
            }
        }
    }
}
