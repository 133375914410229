/*
 * jQuery FlexSlider v2.3.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Ville Ristimäki (@villeristi)
 * 
 */



/* ====================================================================================================================
 * DJO CUSTOM
 * ====================================================================================================================*/



ul.slides, li.slide {
  height:100%;
}

ul.slides li.slide {user-select:none}


ol.flex-control-paging {
  left:0;
  bottom:40px;
  width:100%;
  z-index: 70;
  text-align: center;
  @include breakpoint ($tablet) {}
  @include breakpoint ($mobile) {}
  li {
    height:25px; 
    width:25px;
    display: inline-block;
    a {
        height:100%;
        width: 100%;
        display: inline-block;
        position: relative;
        &::after{
            content: "";
            border-radius:20px;
            border:1px solid #fff;
            position: absolute;
            transition: 0.5s;
            left:9px;
            top:9px;
            width: 13px; height: 13px;
        }
    }
    a.flex-active {cursor: default;}
    a.flex-active, a:hover {
        &::after{ background: #fff; }
    }
  }
}


.flex-viewport {
  height:100% !important;
}
ol.flex-control-nav {
  list-style-type: none;
  position: absolute;
}
.flex-control-paging li a {
  cursor: pointer;
  user-select:none;
  transition: background 0.1s;
}


/* ====================================================================================================================
 * BASE STYLES
 * ====================================================================================================================*/

.flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden;
}

.flexslider .slides:after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
html[xmlns] .flexslider .slides {
  display: block;
}
* html .flexslider .slides {
  height: 1%;
}
.no-js .flexslider .slides > li:first-child {
  display: block;
}
/* ====================================================================================================================
 * DEFAULT THEME
 * ====================================================================================================================*/
.loading .flex-viewport {
  max-height: 300px;
}
.carousel li {
  margin-right: 5px;
}
.flex-direction-nav {
  height: 0;
}
.flex-direction-nav a {
  text-decoration: none;
  display: block;
  width: 40px;
  height: 40px;
  margin: -20px 0 0;
  position: absolute;
  top: 50%;
  z-index: 10;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
}

.flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden;
}
.flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0;
}

.flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default;
}
