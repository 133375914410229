/* NAV SIDES
====================*/


.navLevel2 {
    >ul>li>a, >ul>li:not(.current-menu-parent)>ul>li, >ul>li:not(.current-menu-parent)>ul {
        display: none;
    }
    >ul>li>ul {
        margin: 50px 20% 0 45px;
        @include brk(1400px) {margin-right: 15%}
        @include brk($t) {margin-right: 45px;}
        @include brk($m) {margin: 50px 6.5% 0 6.5%;}
        a {
            font-size:17px;
            font-weight: $bold;
            min-height:60px;
            display: inline-block;
            padding-top:17px;
            padding-bottom:16px;
            line-height: 1.6;
            width:100%;
            color: #2c2b21;
            border-top:1px solid rgba(1,1,1,0.2);
        }
        li.current_page_item a, a:hover {color:$orange;}
        li:first-child a {border-top:none;}
    }
}
.navLevel3 {
    margin-bottom: 40px;
    >ul>li>a, >ul>li>ul>li>a, >ul>li>ul>li:not(.current-menu-parent) {
        display: none;
    }
    >ul>li>ul>li>ul {
        margin: 55px 20% 0 45px;
        @include brk(1400px) {margin-right: 15%}
        @include brk($t) {margin-right: 45px;}
        @include brk($m) {margin: 55px 6.5% 0 6.5%;}
        a {
            font-size:16.5px;
            font-weight: 700;
            min-height:62px;
            display: inline-block;
            padding-top:17px;
            padding-bottom:16px;
            width:100%;
            color: #2c2b21;
            line-height: 1.6;
            border-top:1px solid rgba(1,1,1,0.2);
        }
        li.current_page_item a, a:hover {color:$white;}
    }
}

.navCategory {
    margin-bottom: 110px;
    display: block;
    min-height: 100px;
    width:100%;
    @include brk($t) {margin-bottom: 80px;}
    >ul {
        margin-top: 55px;
        margin-left: 45px;
        margin-right: 20%;
        @include brk(1500px) {margin-right: 45px;}
        @include brk($t) {margin-right: 45px;}
        @include brk($m) {margin: 55px 6.5% 0 6.5%;}
        >li>h4 {
            font-size:16.5px;
            font-weight: 700;
            min-height:62px;
            display: inline-block;
            padding-top:18px;
            padding-bottom:16px;
            padding-right:20px;
            width:100%;
            color: #2c2b21;
            line-height: 1.6;
            border-bottom:1px solid rgba(1,1,1,0.15);
            position:relative;
            cursor:pointer;
            &:after {
                content: "›";
                position: absolute;
                right:0px;
                top:17px;
                padding-left:2px;
                font-weight: 700;
                font-size:16.5px;
                transition: right 0.2s;
                transition: transform 0.2s;
                transform: rotate(90deg);
            }
            &.opened {
                &:after {
                    right:2px;
                    transform: rotate(270deg);
                    transition: right 0.2s;
                    transition: transform 0.2s;
                }
            }
            &:hover {
                color:$white;
            }
        }
        >li>ul {
        	min-height:1px;
            padding-top:24px;
            padding-bottom:3px;
            // display: none;
            border-bottom:1px solid rgba(1,1,1,0.15);
        }
        >li>ul>li>a {
            display: block;
            padding-left:10px;
            line-height: 1.75;
            padding-bottom:18px;
            font-size:14px;
            letter-spacing: 0.8px;
            font-weight:600;
        }
        li.current_page_item a, a:hover, a.selected {color:$white;}
    }
}