





nav.nav_side {
	@include brk($t) {
		padding-top:20px;
	}
	@include brk($m) {
		display: none;
	}
	>ul>li>a, >ul>li:not(.current-menu-parent)>ul>li, >ul>li:not(.current-menu-parent)>ul {
        display: none;
    }

    >ul>li {
    	>ul {

    	}

    	>ul>li {
    		display: inline-block;
    		border-top:1px solid transparentize(#000, 0.9);
    		&:first-child {border-top:none;}
    		&:hover {
    			a {
    				color:$touche_de_couleur2;
    			}
    		}
    		@include brk($t) {
    			width: 100%;
    		}
    	}

    	>ul>li>a {
    		font-size:15px;
            font-weight: $light;
            display: inline-block;
            padding:18px 0;
            letter-spacing: 0.1px;
            line-height: 1.6;
            width:203px;
            color: #727272;
    		position: relative;
    		@include brk($t) {
    			width: 100%;
    		}
    	}
    	>ul>li.current-menu-item a {
    		font-weight: $bold;
    		color:#222d61;
    		&::after {
		        content:"›";
		        position: absolute;
		        font-size:28px;
		        font-weight: $normal;
		        color:$touche_de_couleur;
		        top:5px;
		        right:0px;
		        text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.1);
		    }
    	}
    }
}