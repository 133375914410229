


.flexible {
    li:first-child .flex-titre {
        // margin-top: 0!important;
    }
    li:last-child .flex {
        // margin-bottom: 0;
    }
    .banner-content {
        // padding:0;
        // max-width: none;
    }
    .flex {
        display: inline-block;
        position: relative;
        width:100%;
        .titre {
            font-size: 45px;
            line-height: 1;
            font-weight: $normal;
            letter-spacing: 6px;
            color:#625538;
            padding-bottom:18px;
            @include brk ($tp) { font-size: 40px; }
            @include brk ($m) { font-size: 30px; }
        }
        .chapeau p {
            font-size: 18px;
            font-weight: $normal;
            line-height: 1.5;
            // font-style: italic;
            font-family: $family2;
            color:#625538;
            margin-bottom: 20px;
            position: relative;
            padding-left:20px;
            &:before {
                content:"";
                display: inline-block;
                background:#625538;
                width:14px;
                height:1px;
                position: absolute;
                left:0px;
                top:20px;
            }
            @include brk($m) {
                font-size: 18px;
                padding-left:0px;
                margin-bottom: 10px;
                &:before {display:none}
            }

        }
        .lire-la-suite {
            margin-top: 20px;
            color:#fff;
            border-radius: 10px;
            text-transform: uppercase;
            font-size: 10px;
            letter-spacing: 2.5px;
            font-weight: $bold;
            padding:20px 30px;
        }
    }
}









.page .flex {
    // .banner-content { padding:0; }

    &.flex-titre-chapeau {
        margin-top: 0px;    
        margin-bottom: 25px;       
        .titre p {
            font-size: 45px;
            font-weight: $bold;
            line-height: 1.2;
            color:#003359;
            padding-bottom:25px;
            border-bottom: 1px solid transparentize(#000, 0.9);
            margin-bottom: 5px;
            @include brk($t) {
                font-size: 40px;
            }
            @include brk($m) {
                font-size: 21px;
            }
        }
    }
    
    &.flex-texte {margin-bottom: 35px;}

    &.flex-wysiwyg {
        margin-bottom: 40px;
        ul li {
            color:$touche_de_couleur;
            font-weight: $bold;
            &:before {
                color:$touche_de_couleur;
            }
        }
    }
    
    &.flex-citation {margin-bottom: 60px;}

    &.flex-focus {
        background:transparentize($touche_de_couleur, 0.965);
        position: relative;
        padding: 30px 55px 30px 47px;
        margin-bottom:40px;
        @include brk($m) {
            padding: 25px 35px 30px 30px;
            width:108%;
        }
        .titre {
            font-size: 27px;
            padding-bottom:10px;
            font-weight: $bold;
            color:$touche_de_couleur3;
        }
        &:before {
            content:"";
            display: inline-block;
            height:100%;
            width:6px;
            position: absolute;
            left:0; top:0;
            background:$touche_de_couleur;
        }
        ul {
            padding: 0 0 0 18px;
            li {
                font-size: 15px;
                font-weight: $normal;
                letter-spacing: 1px;
                padding: 11px 0px;
                margin-bottom: 0;
                border-top:1px solid transparentize(#000, 0.9);
                &:first-child {border-top:none;}
                &, &:before {
                    color: $touche_de_couleur;
                }
                &:before {
                    top:10px;
                    left:-12px;
                    font-weight: inherit;
                }
            }
        }
    }


    &.flex-image {
        height:337px;
        margin-bottom: 27px;
        @include brk ($t) { height:290px; }
        @include brk ($m) { height:150px; }
        &.has-lightbox .image {
            cursor:pointer;
        }
    }









    // IMAGE | TEXTE,  TEXTE | IMAGE --------------------------------

    &.flex-image_texte, &.flex-texte_image, {
        margin-bottom: 80px;
        .col-image {
            height:305px;
            width:41%;
            .image {
                // border-radius:6px;
                animation: fade 2s;
            }
            .image-lightbox {
                img {
                    width:100%;
                    visibility: hidden;
                    height:auto;
                }
            }
        }
        .col-texte {
            width:51%;
            ul.caracteristiques {
                width:100%;
                li {
                    padding: 17px 0;
                    margin:0;
                    border-bottom: 1px solid transparentize(#625538, 0.85);
                    &:before {display:none;}
                    .caracteristique, .valeur {
                        display: inline;
                        padding:0;
                    }
                    .caracteristique {
                        font-weight: $bold;
                    }
                }
            }
        }
        @include brk ($t) {
            margin-bottom:70px;
        }
        @include brk($tp) {
            .col-image, .col-texte {
                width:100%;
                float: none!important;
            }
            .col-image {
                height:275px;
                margin-bottom:50px;
                .image-lightbox img {
                    margin: 0 auto;
                    display: block;
                    width:60%; 
                }
            }
        }
        @include brk ($m) { 
            margin-bottom:55px;
            .col-image {
                height:200px;
                margin-bottom:50px;
                .image {
                    width:130%;
                    left:-15%;
                }
            }
            ul.caracteristiques li {
                padding: 12px 0 !important;
                &:last-child {
                    border-bottom:none;
                }
            }
        }
    }

    &.flex-image_texte {
        .col-texte { float: right; }
    }
    &.flex-texte_image {
        .col-image { float: right; }
    }








    // FLEX ACCORDEON -----------------------------------------


    &.flex-accordeon {
        .ul-accordeon {
            // margin-bottom:120px;
        }
        .ul-accordeon>li {
            position: relative;
            display: inline-block;
            display: inline-block;
            width:100%;
            border-bottom: 1px solid rgba(0,0,0,0.1);
            .bloc-titre-container {
                cursor:pointer;
                padding-right: 45px;
                display: inline-block;
                width:100%;
                position: relative;
                .bloc-titre {
                    padding: 24px 0 26px;
                }
                &:hover {
                    .icon-container {
                        background:$touche_de_couleur;
                        // transform: scale(1);
                    }
                    .st0{stroke:#fff !important;}
                }
                .date {
                    cursor:pointer;
                    display: inline;
                    font-size: 14.5px;
                    font-weight: $bold;
                    color:$touche_de_couleur;
                    padding-bottom:0;
                    user-select:none;
                    margin-right: 10px;
                    // animation: fade 2s;
                }
                .titre {
                    cursor:pointer;
                    display: inline;
                    font-size: 14.5px;
                    // animation: fade 2s;
                    font-weight: $normal;
                    // user-select:none;
                    color:$touche_de_couleur;
                }
                .chapeau p {
                    font-size: 12px;
                    font-weight: $light;
                    color:#797979;
                    letter-spacing: 0.2px;
                    line-height: 1.8;
                    padding-bottom:0;
                    cursor: pointer;
                }
                .icon-container {
                    display: inline-block;
                    width:38px;
                    height:38px;
                    background:#fff;
                    border-radius:50%;
                    position: absolute;
                    right:0px;
                    top:17px;
                    text-align: center;
                    cursor: pointer;
                    // transform: scale(1.05);
                    transition: transform 0.3s;
                    .icon {
                        width:18px;
                        height:18px;
                        margin-top: 10px;
                        transition: transform 0.2s;
                    }
                    svg {
                        transition: transform 0.3s;
                        transform: scale(0.5);
                    }
                    .st0{fill:none;stroke:#82827f;stroke-width:8;stroke-miterlimit:10;}
                    .hor {transition: opacity 0.2s;}
                }
            }
            .bloc-contenu {
                padding-top: 10px;
                padding-bottom: 20px;
                opacity: 0;
                transition: opacity 0.5s;
                overflow: hidden;
                .image-container {
                    position: relative;
                    height:337px;
                    margin-bottom: 25px;
                    @include brk ($t) { height:290px; }
                    @include brk ($m) { height:118px; }
                    &.has-lightbox {cursor:pointer}
                }
                .col {
                    position: relative;
                    height:auto;
                    .image-col {
                        transform: translate3d(0,12px,0);
                        width:100%;
                        height:auto;
                        display: block;
                        &.has-lightbox {
                            + .overlay {
                                cursor:pointer;
                                // &:hover {transition: opacity 0.5s; opacity: 1;}
                            }
                        }
                    }
                    @include brk($m) {
                        width:100%!important;
                    }
                    .overlay {
                        @extend .abs;
                        opacity: 0;
                        transition: opacity 0.3s;
                        background:rgba(0,0,0,0.1);
                        .icon {
                            width: 34px;
                            height: 34px;
                            position: absolute;
                            bottom:0;
                            right:0;
                            // transform:rotate(270deg);
                            .st0 {fill:#fff}
                        }
                    }
                }
                .col1 {width:23.2%;}
                .col2 {float:right;width:71.8%;}
            }
            &.opened {
                .bloc-contenu {transition: opacity 1.5s; opacity: 1}
                .bloc-titre .icon-container {
                    background:$touche_de_couleur;
                    .st0{stroke:#fff !important;}
                }
                .bloc-titre:hover .icon-container {
                    background:#e71e4b;
                }
                .icon-container .icon {transform: rotate(180deg);}
                .icon-container .vert {opacity:0}
            }
        }
    }
}





.flex-focus_fond_bleu {
    padding: 65px 0 60px;
    background:#051637;
    margin-top: -5px;
    margin-bottom: 80px;
    animation: fade 2s !important;
    @include brk($t) {
        padding: 65px 0 75px;
    }
    .content {
        position: relative;
    }
    .titres-container{
        width: 30%;
        float: left;
        padding-right:6%;
        margin-bottom: 20px;
        @include brk($t) {
            width:100%;
            clear: both;
        }
        .titre {
            color:#fff;
            font-size: 25px;
        }
        .chapeau p {
            color:#92b5e2;
            font-weight: $light;
            line-height: 2;
        }
    }
    .liste-container {
        width: 40%;
        float: left;
        @include brk($t) {
            width:100%;
            clear: both;
        }
        ul {
            margin-top: -13px;
            @include brk($t) {
                margin-top: -5px;
                margin-bottom: 10px;
            }
        }
        li{
            &:before {display:none}
            padding-left: 46px;
            &:last-child p {border:none!important}
            .icon {
                position: absolute;
                top:14px;
                left:8px;
                height:17px;
                width:17px;
                .st0{fill:#dd5953;stroke:#dd5953;stroke-width:7;stroke-linecap:square;}
            }
        }
        p {
            color:#fff;
            font-size: 16px;
            font-weight: $light;
            padding-bottom:17px;
            border-bottom:1px solid transparentize(#fff, 0.9);
        }
    }
    .btn-01 {
        background: #dd5953;
        border-radius:5px;
        position: absolute;
        right:0;
        @include brk($t) {
            position: relative;
            right:auto;
        }
        &:hover {
            background: darken(#dd5953, 15%);
        }
    }
}   





.flex-symptomes {
    text-align: center;
    background:#f8f8f8;
    padding:60px 0 67px;
    margin-bottom: 85px;
    .titre {
        padding-bottom: 17px;
    }
    .sous-titre {
        font-size: 14px;
        font-weight: $bold;
        color:$touche_de_couleur;
        margin-bottom: 55px;
    }
    .col {
        width:16.66%;
        @include brk($t) {
            width:33.33%;
            height:280px;
        }
        @include brk($m) {
            width:100%;
            height: auto;
            margin-bottom: 50px;
        }
        .image-container {
            position: relative;
            height:160px;
            width:160px;
            margin: 0 auto 27px;
            .image {
                border-radius:50%;
                overflow:hidden;
            }
            @include brk($t) {
                margin-bottom: 23px;
            }
        }
        .texte-container {
            p {
                font-size: 14px;
                text-align: center!important;
                font-weight: $regular;
                padding: 0 20px;
            }
        }
    }
}









// APPEL A ACTION ----------------------------------------------



.flex-appel {
    background:#2e2b26;
    text-align: center;
    margin-bottom: 40px;
    .image-fond {
        background-image:url('../assets/images/fond-appel.jpg');
        background-position: 50% 50%;
        background-size:cover;
    }
    .banner-content {
        padding-top: 60px;
        padding-bottom: 55px;
        @include brk($t) { padding-top: 50px; }
        @include brk($m) { padding-top: 45px; }
    }
    .sur-titre {
        font-size: 22.5px;
        font-family: $family2;
        font-style: italic;
        color:#e0ceb1!important;
        position: relative;
        margin-bottom: 15px;
        font-weight: $normal;
        @include brk($m) { font-size: 17px; }
        &:before, &:after {
            content:"";
            height:1px;
            width:15px;
            background:#e0ceb1;
            font-size: 64px;
            display: inline-block;
            margin: 0 12px;
            transform: translate3d(0,-7px,0);
            @include brk($t) {
                background:#968d78;
                transform: translate3d(0,-6px,0);
            }
            @include brk($m) {
                transform: translate3d(0,-4px,0);
            }
        }
    }
    .appel-titre {
        font-size: 50px;
        font-family: $family2;
        text-transform: uppercase;
        color:#fff!important;
        position: relative;
        margin-bottom: 50px;
        line-height: 1.4;
        letter-spacing: 2px;
        @include brk($t) { font-size: 40px; }
        @include brk($m) { 
            font-size: 30px; 
            margin-bottom: 35px;
            span {display:block;}
        }
    }
    .btn { 
        position: relative;
        border:1px solid transparentize($touche_de_couleur, 0.5);
        p {
            font-size: 10px;
            text-transform: uppercase;
            font-weight: $bold;
            letter-spacing: 3px;
            line-height: 1;
            color:#fff;
            position: relative;
            display:inline-block;
            padding: 24px 25px;
        }
        .icon-download {
            width:14px;
            height:14px;
            margin-left: 23px;
            margin-right: -15px;
            transform: translate3d(0,5px,0);
            // position: absolute;
            // left:0;
            // top:23px;
            display:inline-block;
            path, rect {fill:#fff; transition: transform 0.3s;}
            path {transform: translate3d(0,0,0);}
        }

        &:hover {
            background:#fff;
            p {
                color:#2e2b26;
            }
            path, rect {fill:#2e2b26}
            path {transform: translate3d(0,2px,0);}
        }
    }
}




















// PARTENAIRES ----------------------------------------------


.flex-partenaires {
    background:#f7f6f5;
    padding:60px 0 80px;
    margin-bottom: 120px;
    @include brk($tp){
        padding:45px 0 30px;
        margin-bottom: 80px;
    }
    .titre-section {
        font-size: 25px;
        color:#625538;
        text-transform: uppercase;
        letter-spacing: 4px;
        margin-bottom: 10px;
        font-weight: $normal;
        @include brk($tp){
            text-align: center;
        }
    }
    .sous-titre {
        font-size: 17px;
        font-style: italic;
        color:#625538;
        font-weight: $regular;
        font-family: $family2;
        letter-spacing: 0;
        @include brk($tp){
            text-align: center;
        }
    }

    ul {
        padding-top: 80px;
        @include brk($tp){
            padding-top: 40px;
        }
        li {
            display: inline-block;
            width:33.33%;
            height:127px;
            margin-bottom: 30px;
            float: left;
            @include brk($t){
                width:50%;
            }
            @include brk($tp){
                width:100%;
                height:auto;
                margin-bottom: 35px;
            }
            .image-container {
                width:127px;
                height: 100%;
                float:left;
                background:#fff;
                .image {
                    width:80%;
                    height:80%;
                    top:10%;
                    left:10%;
                    background-size:contain;
                    filter:saturate(0%) sepia(10%);
                }
                @include brk($tp){
                    height:127px;
                    float: none;
                    display: block;
                    margin: 0 auto;
                    margin-bottom: 15px;
                }
            }
            &:hover {
                .image {
                    filter:saturate(100%) sepia(0%);
                }
            }
            .infos-container {
                padding-top:4px;
                width:calc(100% - 127px);
                float:right;
                padding-left:20px;
                @include brk($tp){
                    float: none;
                    margin: 0 auto;
                    text-align: center;
                    padding:0px;
                    width: 100%;
                }
                .entreprise {
                    font-size: 14px;
                    text-transform: uppercase;
                    font-weight: $bold;
                    color:#625538;
                    @include brk($tp){
                        display: inline-block;
                        width: 100%;
                    }
                }
                p, a {
                    font-size: 14px;
                    line-height: 1.70;
                    font-weight: $light;
                    color:#968d78;
                    display: block;
                    float:left;
                    clear: both;
                    @include brk($tp){
                        float: none;
                        display: inline-block;
                        width: 100%;
                    }
                }
                span {
                    @include brk($tp) {
                        display: inline-block;
                        width: 100%;
                    }
                }
                .courriel, .site {
                    position: relative;
                    display: inline-block;
                    @include brk($tp) {
                        width: auto;
                    }
                    &:after {
                        content:"";
                        width:100%;
                        height:1px;
                        display: inline-block;
                        position: absolute;
                        bottom:3px;
                        left:0;
                        right:0;
                        background:transparentize(#968d78, 0.5);
                    }
                    &:hover:after {
                        height: 2px;
                        bottom:2px;
                        opacity: 1;
                        background:transparentize(#968d78, 0);
                    }
                }
                .site {
                    &:after {
                        opacity: 0;
                    }
                }
            }
        }
    }


}