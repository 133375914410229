
$headroom_background_fade_out: 1s;
$headroom_background_opacity: 0.96;

$headroom_speed_in: 0.6s;
$headroom_speed_out: 0.8s;


@keyframes slideUp {
    0% { transform: translateY(0); }
    100% { transform: translateY(- $header-height); }
}

@keyframes slideDown {
    0% { transform: translateY(- $header-height); }
    100% { transform: translateY(0); }
}


header#header>.background {
    // background: #fff;
}


header#header.headroom {
    will-change: transform;
    animation-fill-mode: both;
    // animation-timing-function: ease-in-out;
    .background {
        transition: opacity $headroom_background_fade_out;
        opacity: $headroom_background_opacity;
    }
    &.headroom--top {
        .background {
            opacity: 0!important;
            transition: opacity $headroom_background_fade_out;
        }
    }
    &.headroom--pinned {
        animation-duration: $headroom_speed_in;
        animation-name: slideDown;
        .background {
            transition: opacity $headroom_background_fade_out;
            opacity: $headroom_background_opacity;
        }
    }
    &.headroom--unpinned {
        animation-duration: $headroom_speed_out;
        animation-name: slideUp;
        transform: translateY(- $header-height);
        .background {
            opacity: $headroom_background_opacity;
        }
    }
}



#hamburger_container.headroom, #calling_button.headroom {
    will-change: transform;
    animation-fill-mode: both;
    &.headroom--top {
    }
    &.headroom--pinned {
        animation-duration: $headroom_speed_in;
        animation-name: slideDown;
    }
    &.headroom--unpinned {
        animation-duration: $headroom_speed_out;
        animation-name: slideUp;
    }
}

