


.btn-01 {
    background: $touche_de_couleur;
    padding:0!important;
    margin-left:0!important;
    &:hover {
        background: darken($touche_de_couleur, 15%);
        .icon span {transform:translate3d(1px,0,0);}
    }
    p {
        color:#fff;
        font-weight: $bold;
        font-size: 16.5px;
        padding:18px 90px 16px 30px;
        letter-spacing: 1.5px;
        display: inline-block;
        user-select:none;
        // text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.34);
    }
    .icon {
        font-size: 30px;
        color:#fff;
        width:52px;
        text-align: center;
        font-weight: $normal;
        height:34px;
        position: absolute;
        top:13px;
        line-height: 1;
        right:6px;
        border-left:1px solid rgba(255,255,255,0.3);
        span {
            transition: transform 0.5s;
            display: inline-block;
            // text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.34);
        }
    }
    &:after {
        display: none!important;
    }
}













// SCROLL BTN ---------------------------------------

@keyframes scroll_anim {
    0% {
        opacity: 0;
        transform: translate3d(0,40px,0);
    }
    20% { opacity: 1; }
    100% { transform: translate3d(0,0px,0); }
}

.scroll-btn {
    width:62px;
    height:62px;
    position: absolute;
    bottom:-31px;
    left:calc(50% - 31px);
    z-index: 30;
    text-align: center;
    cursor: pointer;
    animation: scroll_anim 1s;
    @include brk($td) {
        display: none;
    }
    .icon {
        background: #fff;
        width:100%;
        height:100%;
        border-radius:50%;
        box-shadow: 0px 3px 29px 0px rgba(79, 70, 48, 0.4);
        transition: transform 0.5s, background 0s;
        svg {
            width:20px;
            height:20px;
            display: inline-block;
            margin-top: 21px;
            .st0{fill:#625538;}
        }
        @include brk($m) {
            svg {
                margin-top: 19px;
                width:16px;
                height:16px;
            }
        }
    }
    &:hover {
        .icon {
            transform: scale(1.08);
            // background: darken($touche_de_couleur, 20%)!important;
        }
    }
}













// BANNER CALL-TO-ACTION -----------------------------------


.banner.bottom.call-to-action {
    height: 360px;
    text-align: center;
    position: relative;
    @include brk($t) {
        height: 325px;
    }
    @include brk($m) {
        height: 300px;
    }
    .image-container {
        height:calc(100% + 90px);
        position: absolute;
        bottom:0;
        left:0;
        right:0;
        width:100%;
        .image {
            background-image: url('../assets/images/call-to-action.jpg');
            background-size:contain;
            background-position:50% 100%;
        }
        @include brk($m) {
            height:calc(100% + 0px);
            width: 280%;
            left:-90%;
        }
    }
    .sur-titre {
        font-size: 21px;
        font-family: $family2;
        font-style: italic;
        letter-spacing: 0px;
        position: relative;
        color:#4f4630;
        padding-top: 50px;
        margin-bottom: 5px;
        &:before, &:after {
            content: "-";
            margin: 0 5px;
        }
        @include brk($t) {
            margin-bottom: 0px;
        }
        @include brk($m) {
            font-size: 14px;
            margin-bottom: 10px;
            span {display:block}
            &:before, &:after {display:none;}
        }
    }
    .titre {
        font-family: $family2;
        font-size: 52.5px;
        text-transform: uppercase;
        margin-bottom: 62px;
        letter-spacing: 5.5px;
        position: relative;
        color:#4f4630;
        @include brk($t) {
            font-size: 42.5px;
            margin-bottom: 40px;
        }
        @include brk($m) {
            font-size: 21.5px;
            margin-bottom: 30px;
            letter-spacing: 3px;
        }
    }
    .btn {
        font-size: 10px;
        text-transform: uppercase;
        font-weight: $bold;
        letter-spacing: 2.2px;
        line-height: 1;
        position: relative;
        border:1px solid $touche_de_couleur;
        padding: 19px 23px;
        background:#fff;
        &:hover {
            background:transparentize(#2e2b26, 0);
            color:#fff;
        }
    }
}