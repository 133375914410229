
$search-field-height-desktop : 52px;


.searchField {
	// display:block;
    display:none;
	height:$search-field-height-desktop;
	width:100%;
	position:absolute;
	top:0;
	left:0;
	z-index:3000;
	@include breakpoint ($tablet) {
	 	height:$header-height-tablet;
	 	position:fixed;
	}
	@include breakpoint ($mobile) {
	 	height:$header-height-mobile;
	}
	.searchForm {
        display: inline-block;
        float:right;
        width:480px;
        height:100%;
        input {
            display:block;
			color:$white;
            font-weight:500;
        }
        input[type="text"] {
        	color:$white;
            font-size: 17.5px;
            letter-spacing: 2px;
            // display: inline-block;
            display:none;
            text-align:right;
            height:100%;
            width:350px;
            float: right;
            padding-right: 20px;
   //          @include select (text);
   //          appearance:field;
			// -moz-appearance:field; /* Firefox */
			// -webkit-appearance:field; /* Safari and Chrome */
			&::-webkit-input-placeholder {
			  color: rgba(255,255,255,0.5);
			}
			&:-ms-input-placeholder {
				color: rgba(255,255,255,0.5);
			}
			&::-moz-placeholder {
				color: rgba(255,255,255,0.5);
			}
			&:-moz-placeholder {
				color: rgba(255,255,255,0.5);
			}
        }
        input[type="submit"] { display: none; }
        .searchMySubmit {
        	background:#1d1d1d;
            width: $search-field-height-desktop;
            float:right;
            height:100%;
            cursor:pointer;
            @include breakpoint ($tablet) {
			 	width:$header-height-tablet;
			}
			@include breakpoint ($mobile) {
			 	width:$header-height-mobile;
			}
            .svg {
            	height:100%;
				width:100%;
            	transform: scale(0.7,0.7);
            	transition: transform 0.05s;
            }
            .st0 {fill:$white;}
            &:hover {
				.svg {
					transition: transform 0.15s;
					transform: scale(0.9,0.9);
				}
			}
        }
    }
}

.searchButton {
	position:absolute;
	top:0;
	right:0;
	z-index:2000;
	.st0 {
		fill:#3b3632;
		transition: fill 0.05s;
		@include breakpoint ($tablet) {
			fill:#616161;
		}
	}
	.inside {
		position:relative;
		float:right;
		width:$search-field-height-desktop;
		height:$search-field-height-desktop;
		opacity:0.8;
		transition: opacity 0.15s;
		@include breakpoint ($tablet) {
			width:$header-height-tablet;
			height:$header-height-tablet;
			background:#000!important;
		}
		@include breakpoint ($mobile) {
			width:$header-height-mobile;
			height:$header-height-mobile;
		}
	}
	.svg {
		position:absolute;
		right:0;
		top:0;
		height:100%;
		width:100%;
		transform: scale(0.7,0.7);
		transition: transform 0.05s;
	}
	&:hover {
		cursor:pointer;
		.inside {
			opacity:1;
			transition: opacity 0.15s;
		}
		.svg {
			.st0{
				transition: fill 0.2s;
			}
			transition: transform 0.15s;
			transform: scale(0.9,0.9);
		}
	}
}

.searchButton.desktop {
	height:100px;
	width:100px;
	@include breakpoint ($tablet) { display:none !important; }
}

.searchButton.tablet {
	width:$header-height-tablet;
	height:$header-height-tablet;
	right:$header-height-tablet;
	@include breakpoint ($mobile) {
		width:$header-height-mobile;
		height:$header-height-mobile;
		right:$header-height-mobile;
	}
}

.searchResults {
	h2 {
		padding-bottom:60px;
	}
	>.trait {
		width: 40px; 
		height: 2px; 
		margin-bottom: 40px;
	}
	.oneResult {
		display:block; 
		margin: 0 0 40px 0 !important;
		border-bottom:none !important;
		&:last-child {border-bottom:none !important;}
		.title, .date {
			font-style:normal;
		}
		.date {
			padding-bottom:10px;
		}
		p {}
		.trait {
			width: 40px; 
			height: 2px; 
		}
		&:last-child .trait {display:none;}
	}
}
