

// ACCUEIL HEIGHT BANNER TOP

$pgac_bT_h-desktop: 608px;
$pgac_bT_h-laptop: 608px;
$pgac_bT_h-tablet: 728px;
$pgac_bT_h-mobile: 452px;



.front-page .banner.top {
    // background: $footer-color;
    height:$pgac_bT_h-desktop;
    // transition: height 1s;
    @include brk ($l) { height:$pgac_bT_h-laptop; }
    @include brk ($td) { height:$pgac_bT_h-tablet; }
    @include brk ($m) { height:$pgac_bT_h-mobile; }
    .bloc-container {
        line-height: $pgac_bT_h-desktop;
        @include brk ($l) { line-height:$pgac_bT_h-laptop; }
        @include brk ($td) { line-height:203px; }
        @include brk ($m) { line-height:127px; }
    }
}


@keyframes text_shadow_fade {
    0%{
        text-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    }
    100% {
        text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.5);
    }
}



.front-page .banner.top {
    margin-bottom: 100px;
    @include brk($td) {
        margin-bottom: 0px;
    }
    @include brk($m) {
        margin-bottom: 0px;
    }
    .image {
        display:none;
        // background-image:url('../assets/images/ferme-oudoumont-1.jpg');
        animation: fade 1s;
        @include brk($t){
            display: inline-block;
        }
        .gradient {
            position: absolute;
            height:150px;
            top:auto;
            bottom:-1px;
            left:0;
            right:0;
            display: none;
            background: linear-gradient(to bottom, 
                transparentize(#fff, 1), 
                transparentize(#fff, 0.8) 30%, 
                transparentize(#fff, 0.15) 80%, 
                transparentize(#fff, 0)
            );
        }
        @include brk($td) {
            height:525px;
        }
        @include brk($td) {
            .gradient {display:inline-block;}
        }
        @include brk($m) {
            height:325px;
        }
    }
    .banner-content {
        @include brk($m) {
            padding:0 15px;
        }
    }
    .video-container {
        height:100%;
        overflow: hidden;
        position:absolute;
        width: 100%;
        @include brk($td) {
            height:525px;
        }
        @include brk($td) {
            display: none!important
        }
        @include brk($m) {
            height:325px;
        }
        video {
            // animation: fade 2s;
            width:100%;
            min-width: 1700px;
            position: relative;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%,0);
            @include brk($td) {
                height: 100%;
            }
        }
    }
    .gradient-top {
        animation: fade 2s;
        width:100%;
        height:110px;
        position: absolute;
        top:0;
        left:0;
        right:0;
        z-index: 10;
        background: linear-gradient(to top, 
            transparentize(#000, 1) 0%,
            transparentize(#000, 0.9) 35%,
            transparentize(#000, 0.85) 70%,
            transparentize(#000, 0.7) 100%
        );
        @include brk($td) {display:none}
        // display: none;
    }
    .gradient-bottom {
        animation: fade 2s;
        width:100%;
        height:110px;
        position: absolute;
        bottom:0;
        left:0;
        right:0;
        z-index: 10;
        background: linear-gradient(to bottom, 
            transparentize(#000, 1) 0%,
            transparentize(#000, 0.9) 35%,
            transparentize(#000, 0.85) 70%,
            transparentize(#000, 0.65) 100%
        );
        @include brk($td) {display:none}
        // display: none;
    }
    .bloc-container {
        @include brk($td) {
            height:203px;
            padding-top:525px;
        }
        @include brk($m) {
            height:203px;
            padding-top:325px;
        }
        .bloc {
            // width:40%;
            text-align: center;
            padding:260px 10% 0;
            animation: fade 2s;
            @include brk($td) {
                padding:40px 0% 0;
                text-align: center;
            }
            @include brk($td) {
                padding:0px 0% 0;
            }
            .textes {
                .sur-titre {
                    font-size: 20px;
                    color: transparentize(#fff, 0);
                    letter-spacing: 6px;
                    line-height: 2;
                    padding: 0 12%;
                    font-family: $family2;
                    font-weight: $bold;
                    text-transform: uppercase;
                    margin-bottom: 10px;
                    user-select:none;
                    animation: text_shadow_fade 1s;
                    &:before, &:after {
                        content:"";
                        height:1px;
                        width:15px;
                        background:#fff;
                        font-size: 64px;
                        display: inline-block;
                        margin: 0 12px;
                        transform: translate3d(0,-7px,0);
                        @include brk($td) {
                            background:#968d78;
                            transform: translate3d(0,-6px,0);
                        }
                        @include brk($m) {
                            transform: translate3d(0,-4px,0);
                        }
                    }
                    @include brk($td) {
                        font-size: 16px;
                        padding: 0 0;
                        color:#968d78;
                        text-shadow: none;
                        animation: fade 1s;
                    }
                    @include brk($td) {
                        margin-bottom: 5px;
                    }
                    @include brk($m) {
                        font-size: 11px;
                        letter-spacing: 3px;
                        // display: none;
                    }
                }
                .titre {
                    clear: both;
                    animation: text_shadow_fade 1.5s;
                    color: transparentize(#fff, 0);
                    font-size: 75px;
                    font-weight: $normal;
                    display: inline-block;
                    letter-spacing: 1px;
                    // text-transform: uppercase;
                    word-spacing: 2px;
                    margin-bottom: 30px;
                    font-family: $family2;
                    position: relative;
                    line-height: 1.3;
                    user-select:none;
                    text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.5);
                    @include brk($td) {
                        font-size: 60px;
                        color:#4f4630;
                        text-shadow: none;
                        animation: fade 1.5s;
                    }
                    @include brk($td) {
                        margin-bottom: 20px;
                    }
                    @include brk($tp) {
                        font-size: 50px;
                    }
                    @include brk($m) {
                        font-size: 29px;
                        line-height: 1.2;
                        margin-bottom:10px;
                        letter-spacing: 0;
                    }
                    span {
                        color:$touche_de_couleur;
                        font-weight: $light;
                    }
                }
                .sous-titre {
                    color:#fff;
                    font-size: 12.5px;
                    animation: text_shadow_fade 2s;
                    font-weight: $normal;
                    line-height: 1.18;
                    text-transform: uppercase;
                    letter-spacing: 4px;
                    user-select:none;
                    text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.5);
                    @include brk($td) {
                        font-size: 12px;
                        color:#968d78;
                        text-shadow: none;
                        animation: fade 2s;
                    }
                    @include brk($m) {
                        font-size: 9px;
                        line-height: 2;
                    }
                }
                .lien {
                    margin-top: 40px;
                    height:60px;
                    background: $touche_de_couleur2;
                    animation: fade 2.5s;
                    user-select:none;
                    @include brk($m) {
                        margin-top: 20px;
                    }
                    &:hover {
                        background:#003359;
                    }
                    p {
                        padding-top:18px;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}









.front-page {
    .banner.pro, .banner.prive, .banner.historique {
        padding:40px 0 80px;
        @include brk($t) {
            padding:40px 0 40px;
        }
        @include brk($m) {
            padding:42px 0 0px;
        }
        .col {
            &.col1 {
                float: left;
                width:47.5%;
            }
            &.col2 {
                float: right;
                width:43.5%;
                height:370px;
                position: relative;
            }
            @include brk($t) {
                width: 100%!important;
                float: none;
                &.col2 {
                    height:auto;
                }
            }
            @include brk($m) {
                &.col2 {
                    text-align: center!important;
                }
            }
        }
        
        .image-container {
            height:370px;
            width: 100%;
            @include brk($m) {
                height:260px
            }
            
            .image {
                @include brk($m) {
                    width:130%;
                    left:-15%;
                }
            }
        }
        .sur-titre {
            font-size: 21px;
            font-style: italic;
            font-family: $family2;
            color:#968d78;
            user-select:none;
            letter-spacing: 0;
            position: relative;
            display: inline-block;
            &:after {
                content:"";
                position: absolute;
                left:calc(100% + 30px);
                height:1px;
                width: 62px;
                top:19px;
                background:transparentize(#968d78, 0.5);
                @include brk($m) {
                    display: none;
                }
            }
            @include brk($t) {
                margin-top: 35px;
            }
            @include brk($m) {
                margin-top: 5px;
                font-size: 17.5px;
            }
        }
        .titre {
            font-size: 50px;
            font-family: $family2;
            color:#4f4630;
            text-transform: uppercase;
            letter-spacing: 4px;
            margin-bottom: 30px;
            user-select:none;
            @include brk($t) {
                margin-bottom: 10px;
            }
            @include brk($m) {
                font-size: 27.5px;
            }
        }
        .texte {
            p, a {
                font-size: 16.5px;
                font-family: $family2;
                color:#7a705a;
                @include brk($m) {
                    font-size: 12.5px;
                }
            }
            @include brk($m) {
                padding: 0 6%;            }
        }
        .btn {
            font-size: 10px;
            display: inline-block;
            color:#7a705a;
            font-weight: $bold;
            text-transform: uppercase;
            letter-spacing: 2px;
            line-height:1;
            border: 1px solid transparentize(#7a705a, 0.7);
            padding:19px 25px;
            position: absolute;
            bottom:6px;
            left:0;
            @include brk($t) {
                position: relative;
                margin-top: 60px;
                margin-left: 50px;
            }
            @include brk($m) {
                margin-top: 30px;
                margin-left: 0;
                display: none;
            }
            &:hover {
                background:transparentize(#2e2b26, 0);
                color:#fff;
            }
            &:before {
                content:"";
                display: inline-block;
                position: absolute;
                top:23px;
                left:-48px;
                width:61px;
                height:1px;
                background: transparentize(#7a705a, 0.7);
                @include brk($m) {display:none}
            }
        }
    }
    .banner.pro {
        @include brk($m) {
            padding-top:30px;
        }
        .sur-titre {
            @include brk($t) {
                margin-top: 60px;
            }
            @include brk($m) {
                margin-top: 30px;
            }
        }
        .overlay {
            background:#f9f7f2;
            top:60px;
            left:-50%;
            @include brk($t) {
                left:50%;
            }
            @include brk($m) {display:none}
            &:before {
                content:"";
                display: inline-block;
                height:100%;
                width:2000%;
                position: absolute;
                right:100%;
                top:0;
                background:inherit;
                @include brk($t) {
                    right:auto;
                    left:100%;
                }
            }
        }
    }
    .banner.prive {
        .sur-titre {
            &:after {
                left:auto;
                right:calc(100% + 30px);
                @include brk($t) {
                    right:auto;
                    left:calc(100% + 30px);
                }
            }
            @include brk($t) {
                margin-top: 60px;
            }
            @include brk($m) {
                margin-top: 30px;
            }
        }
        .col {
            &.col1 {
                float: right;
                width:47.5%;
            }
            &.col2 {
                float: left;
                width:43.5%;
                text-align: right;
                @include brk($t) {
                    text-align: left;
                }
            }
        }
        .overlay {
            background:#f9f7f2;
            top:60px;
            left:50%;
            @include brk($m) {display:none}
            &:before {
                content:"";
                display: inline-block;
                height:100%;
                width:2000%;
                position: absolute;
                left:100%;
                top:0;
                background:inherit;
            }
        }
        .btn {
            left:auto;
            right:0;
        }
    }
    .banner.historique {
        @include brk($m) {
            padding:40px 0 40px;
        }
        .image {
            background-size:contain;
            @include brk($m) {
                width: 160%!important;
                left: -30%!important;
            }
        }
    }
}

