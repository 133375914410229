
/* NAV TABLET
====================*/




// LEVEL 1
$bg-l1: #fff;
$bg-l1-top: #ede4d6;
$bg-l1-robe: darken($touche_de_couleur3, 0%);


// LEVEL 2
$bg-l2: darken(#ede4d6, 0%);
$bg-l2-top: darken($touche_de_couleur2, 10%);
$bg-l2-robe: darken($touche_de_couleur2, 10%);


// LEVEL 3
$bg-l3: darken(#4cacb2, 6%);
$bg-l3-top: $dark-red;





.btn-home {
    z-index: 1012;
    position: absolute;
    display: inline-block;
    top:0;
    left:0;
    height:70px;
    width:70px;
    background:$white;
    animation: fade 1.5s;
    svg {transform:scale(0.4)}
    path {
        fill: #000;
        fill-rule: evenodd;
     }
    @include brk($t) {height:$header-height-tablet;}
    @include brk($m) {height:$header-height-mobile;}
}





// CONTAINER ------------------------------------

@keyframes open_menu {
    0% { transform:translate3d(100%, 0px, 0px); }
    100% { transform:translate3d(0, 0px, 0px); }
}


#nav_mobile_container {
    transform:translate3d(0px, 0px, 0px);
    transition: transform 0.3s, opacity 0.3s;
    z-index: 990;
    height:100%;
    top:0px;
    right:0;
    overflow: hidden;
    position:fixed;
    display: none;
    user-select:none;
    &.opened {
        display: block;
        animation: open_menu 0.3s;
    }
    @include brk ($t) {width:320px;}
    @include brk ($m) {max-width:calc(100% - 68px);}
}




// LEVEL 1 --------------------------------------------

#nav_mobile {
    height:100%;
    overflow: hidden;
    background:$bg-l1-robe;
    >ul {
        margin-top:$header-height;
        @include brk($t) {margin-top:$header-height-tablet;}
        @include brk($m) {margin-top:$header-height-mobile;}
    }
    >ul li:first-child {
        padding-top:5px; 
        a {border:none!important;}
    }
    >ul li a {
        line-height: 1;
        font-size:11.5px;
        position:relative;
        padding: 24px 0 22px 30px;
        letter-spacing: 2px;
        animation: fade 1.5s;
        font-weight: $bold;
        text-transform: uppercase;
        border-top:1px solid transparentize(#000,0.9);
        transition: border 0.6s;
    }
    >ul>li {
        background:$bg-l1;
    }
    >ul li {
        &:hover, &.current-menu-item, &.current-menu-ancestor {
            background:darken($bg-l1, 6%);
            >a, + li>a {border-top:1px solid transparentize(#000,1);}
        }
    }
    >ul>li>a {
        display: inline-block;
        position: relative;
        width:100%;
        color:$touche_de_couleur2;
    }
    >ul>li.menu-item-has-children>a::after {
            content:"›";
            position: absolute;
            font-size:28px;
            font-weight: $normal;
            height:100%;
            box-sizing: inherit;
            color:$touche_de_couleur;
            width:62px; 
            top:8px;
            right:0px;
            padding-left:25px;
            padding-top: 4px;
            // background:#edecec;
            // text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.15);
            @include brk($m) {}
        }
}






// LEVEL 2 --------------------------------------------


#nav_mobile >ul>li {
    ul {
        z-index: 1012;
        top:$header-height;
        @include brk($tablet) {top:$header-height-tablet;}
        @include brk($mobile) {top:$header-height-mobile;}
        li>a {
            letter-spacing: 1px;
            color:#eee;
            display: block;
            opacity: 0.1;
            transition: opacity 0.35s;
            color:$touche_de_couleur2;
        }
        &.opened {
            transform: translate3d(7.4%, 0px, 0px);
            >li>a {
                opacity: 1;
            }
        }
    }
    >ul>li {
        background:$bg-l2;
    }
    >ul {
        background:$bg-l2-robe;
        overflow-y: scroll;
        overflow-x: hidden;
        height:100%;
        width: 108.1%;
        position:absolute;
        right:1px;
        transform:translate3d(108%, 0px, 0px);
        transition: transform 0.3s;
    }
    >ul>li {
        &.current-menu-item, &:hover {
            background:darken($bg-l2, 6%);
            >a, + li>a {border-top:1px solid transparentize(#fff,1);}
        }
    }
}






// LEVEL 3 ---------------------------------------------

#nav_mobile >ul>li>ul>li{
    ul{
        transform:translate3d(0px, 0px, 0px);
        transition: transform 0.3s;
        background:$bg-l3;
        display:none;
        position:fixed;
        height:100%;
        a {
            color:#333333;
            font-weight: 700;
            &:hover {
                background:darken($bg-l3, 6%);
            }
        }
    }
}










// @import "hamburger/00_hamburger";
// #hamburger_container {
//     position: fixed;
//     background: transparentize($touche_de_couleur3, 1);
//     animation: fade 1s;
//     width:$header-height; 
//     height:$header-height;
//     z-index: 1010;
//     right:0;
//     top:0;
//     margin-right: - ($header-height + 50px);
//     &.active {
//         // animation:none!important
//         background: $touche_de_couleur;
//     }
//     @include brk($t) {
//         display:inline-block;
//         margin-right: 0;
//         width:$header-height-tablet; 
//         height:$header-height-tablet;
//     }
//     @include brk($m) {
//         width:$header-height-mobile; 
//         height:$header-height-mobile;
//     }
//     button {
//         &.is-active {
//             background: transparentize($touche_de_couleur3, 0);
//         }
//     }
//     // button {
//     //     &:before {
//     //         content:"";
//     //         @extend .abs;
//     //         background:url('../assets/images/sandwish-gradient.png');
//     //         height:80%;
//     //         background-size:cover;
//     //         background-position:0% 0%;
//     //         opacity: 1;
//     //     }
//     // }
// }






// TOP LINE NAMES

.bar-top {
    display: block;
    height:$header-height-tablet;
    line-height: $header-height-tablet;
    position:absolute;
    transform:translate3d(0px, 0px, 0px);
    transition: transform 0.3s;
    top:0px;
    right:0px;
    width:100%;
    p {
        padding-left: 30px;
        text-transform: uppercase;
        font-size:9px;
        color:$white;
        letter-spacing: 2px;
        display: inline-block;
        vertical-align: middle;
        line-height: normal!important;
    }
    &::after {content:none;}
    &.l1 {
        background:$bg-l1-top;z-index: 1011;
    }
    &.l2 {
        background:$bg-l2-top;z-index: 1013;
    }
    &.l3 {
        background:$bg-l3-top;z-index: 1015;
    }
    &.l2, &.l3 {
        transform:translate3d(100%, 0px, 0px);
    }
    @include brk($m) {
        height:$header-height-mobile;
        line-height: $header-height-mobile;
    }
}







#nav_mobile .social-icons.mobile {
    height:60px;
    width:100%;
    margin-right: 10px;
    display:none;
    background:#ede4d6;
    padding-top: 12px;
    padding-left:20px;
    @include brk ($t) {display:inline-block;}
    .icon {
        border-radius:50%;
        width:24px;
        height:24px;
        float: left;
        animation: fade_scale 1.2s;
        margin-left: 6px;
        margin-right: 10px;
        margin-top: 6px;
        path {
            transition: fill 0.3s;
            &.st0 {
                fill: $touche_de_couleur3;
                &:hover {
                    fill: darken(#222d62, 10%);
                }
            }
        }
        path.st1, rect {
            fill:#ede4d6
        }
        svg { transform:scale(1.25) }
    }
}






#calling_button {
    height:$header-height-mobile;
    width:$header-height-mobile;
    // background: $touche_de_couleur2;
    position:fixed;
    right:$header-height-mobile;
    top:0;
    z-index: 100;
    animation: fade 1s;
    display: none;
    .icon {
        position: absolute;
        width:50%;
        height:50%;
        background:#c5c5c2;
        top:25%;
        left:25%;
        border-radius:50%;
    }
    svg {
        transform: scale(0.4);
        path {fill: #37382d}
    }
    &:hover {
        // background:darken($touche_de_couleur2, 5%);
    }
    &.page {
        // background: #fff;
        svg path {fill:#222d62;}
        &:hover {
            // background:darken(#fff, 5%);
        }
    }
    @include brk($t) {display: inline-block;}
    @include brk($m) {
        left:0;
    }
}
















// WPML -----------------------------------------------------


.wpml-ls-statics-footer.wpml-ls-legacy-list-horizontal {
    position: absolute;
    // height:100%;
    padding-left:7px;
    display: none;
    overflow: hidden;
    z-index: 1012;
    animation: fade 2s;
    @extend .bar-top;
    >ul>li {
        display: inline-block;
        position: relative;
        &:first-child:after {
            content: "";
            background:transparentize(#2e2b26, 0.5);
            height:15px;
            width:1px;
            display: inline-block;
            position: absolute;
            right:0;
            top:28px;
        }
    }
    >ul>li>a {
        position: relative;
        display: inline-block;
        height:100%;
        font-size: 12.5px;
        font-weight: $bold;
        padding: 25px 22px;
        text-transform: uppercase;
        color:#2e2b26;
        font-family: $family2;
    }
}


