



// PAGE BANNER TOP

$page_wrapper-bannerTop-desktop: 525px;
$page_wrapper-bannerTop-laptop: 490px;
$page_wrapper-bannerTop-tablet: 393px;
$page_wrapper-bannerTop-mobile: 393px;

.page.contact .banner.top {
	transition: height 1s;
	height:$page_wrapper-bannerTop-desktop;
	@include brk($l) { height:$page_wrapper-bannerTop-laptop; }
	@include brk($t) { height:$page_wrapper-bannerTop-tablet; }
	@include brk($m) { height:$page_wrapper-bannerTop-mobile; }
}
.page.contact .banner.top .bloc-container {
	transition: line-height 1.8s;
	line-height:$page_wrapper-bannerTop-desktop;
	@include brk($l) { line-height:$page_wrapper-bannerTop-laptop; }
	@include brk($t) { line-height:$page_wrapper-bannerTop-tablet; }
	@include brk($m) { line-height:$page_wrapper-bannerTop-mobile; }
}



.page.contact {
    background:#fff !important;
}


.page.contact .banner.top {
	position: relative;
	.image {
		background-position: 50% 32% !important;
        background-size: cover !important;
	}
	.bloc {
		@include brk($t) {
			padding: 25px 0 0 0;
		}
	}
	#google_map {
        animation: fade 2.5s;
        height:100%;
        width:100%;
        // height:390px;
        position: absolute!important;
        /*======= Map Styling ============*/
        .gmnoprint a, .gmnoprint span {
            display:none;
        }
    }
    .gradient {
        position: absolute;
        height:180px;
        top:auto;
        bottom:0;
        left:0;
        right:0;
        z-index: 900;
        background: linear-gradient(to bottom, 
            transparentize(#fff, 1), 
            transparentize(#fff, 0.7) 30%, 
            transparentize(#fff, 0.4) 50%, 
            transparentize(#fff, 0.1) 80%, 
            transparentize(#fff, 0)
        );
    }
}












.page.contact .banner.top {
	.image { 
		animation: fade 1s; 
		background-position: 50% 20%;
	}
}








.page.contact .banner.medium {
	overflow:visible;
	padding-top:0;
	animation:none;
    margin-top: -55px;
	.banner-content {
		@include brk($t) { padding:0 }
		.content {
			position: relative;
		}
	}
}





.page.contact .col-left {
    min-height: 300px;
    background:#ffffff;
    float: left;
    width:34%;
    z-index: 921;
    animation: fade 2s;
    @include brk($t) { 
        width: 100%; 
        padding-left:6%;
        padding-right:6%;
        &:after {display:none;}
    }
    @include brk($m) {  
        width: 100%;  
        padding-left:$padding-mobile;
        padding-right:$padding-mobile;
    }
    .inside {
        margin-top: 35px;
        padding: 30px 0% 30px 0px;
        text-align: center;
        animation: fade 1s;
        box-shadow: 0px 3px 29px 0px rgba(79, 70, 48, 0.1);
        @include brk($t) { 
            padding: 50px 0% 40px 0;
        }
        @include brk($m) { 
            // text-align: left; 
            padding: 40px 0% 30px 0;
        }
    }
    .blason-container {
        height:50px;
        margin-bottom: 20px;
        .blason {
            width:34px;
            height:34px;
            margin: 0 auto;
            background-image: url('../assets/images/blason.png');
            background-size: contain;
            background-position: 50% 50%;
        }
    }
    .titre-colonne {
        color:#4f4630;
        font-weight: $bold;
        position: relative;
        margin-bottom: 7px;
        font-size: 12px;
        letter-spacing: 4px;
        font-family: $family2;
        text-transform: uppercase;
    }
    .nom-entreprise {
        font-size: 17px;
        color:#4f4630;
        font-family: $family2;
        font-weight: $bold;
        line-height: 2;        
        span {
            font-weight: $light;
            color:#4a4337;
        }
    }
    .adresses-container {
        padding-bottom:0px;
        .rue, .ville {
            font-size: 17px;
            font-weight: $normal;
            font-family: $family2;
            color:#736c5b;
        }
    }
    .tel-fax-container {
        padding-bottom:5px;
        font-family: $family2;
        span {
            display:block;
            color:#4f4630;
            font-weight: $normal;
            margin-bottom: 15px;
            line-height: 1;
        }
        span.email {
            font-weight: $bold;
            a {
                user-select:text;
            }
        }
        span.tel {
            margin-bottom: 20px;
        }
        span.tel p {
            line-height: 1.4;
            font-size: 19px;
            letter-spacing: 0;
        }
        p, a {
            font-family: $family2;
            display: inline;
            margin-left:5px;
            font-size: 17px;
            color:#736c5b;
            line-height: 2;
        }
        p.personne {
            font-size: 17px;
            font-weight: $bold;
        }
        a {
            font-weight: $regular;
            position: relative;
            &:after {
                content:"";
                display: inline-block;
                position: absolute;
                bottom:1px;
                left:0;
                height:2px;
                width:0;
                opacity: 0;
                transition: opacity 0.5s, width 0.2s;
                background: lighten($touche_de_couleur, 0%);
            }
            &:hover {
                &:after {
                    width:100%;
                    opacity: 1;
                }
            }
        }
        .plan-acces {
            font-weight: $bold;
            font-size: 11.5px;
            text-transform: uppercase;
            letter-spacing: 1.5px;
            border: 1px solid transparentize(#736c5b,0.5);
            padding:7px 17px;
            display: inline-block;
            margin: 7px 0 11px;
            &:after {display:none;}
            &:hover {
                background: darken(#736c5b,10%);
                color:#fff;
            }
        }
    }
    .petit-trait {
        height: 2.5px;
        background:$touche_de_couleur;
        margin-top: 17px;
        margin-bottom:18px;
        width:21px;
    }
    .trait {
        height: 13px;
        border-bottom:1px solid transparentize(#000, 0.9);
        margin: 0 auto;
        margin-bottom:19px;
        display: inline-block;
        width:72%;
        @include brk($t) {  display: none }
    }
    .googlemap-container {
        display: inline-block;
        width: 100%;
        margin-top: 10px;
        @include brk($t) { margin-top: 60px; }
        @include brk($m) { margin-top: 42px; }
        p {
            font-size: 15px;
            font-weight: $bold;
            float: left;
            width:100px;
            margin-top: 13px;
            display: inline-block;
            margin-right: 15px;
            color:$touche_de_couleur3;
            transition: color 1s;
            @include brk($m) { 
                float: left; 
                margin-left: 20px;
            }
        }
        .icon-container {
            float: left;
            height:54px;
            width: 54px;
            background: transparentize($touche_de_couleur, 0.95);
            border-radius: 50%;
            text-align: center;
            margin-right: -5px;
            transition: transform 0.15s;
            @include brk($m) { float: left; }
            .icon {
                margin-top: 13px;
                width:28px;
                height:28px;
                transition: transform 5s;
            }
            path {
                fill:$touche_de_couleur;
                transition: fill 0.2s;
            }
        }
        &:hover {
            // p {color:$touche_de_couleur2}
            .icon-container {transform: scale(0.98)}
            .icon {transform: scale(1.2)}
            path {
                transition: fill 0.2s;
                fill:$touche_de_couleur2
            }
        }
    }
}
















.page.contact .col-right {
	// background:#fff;
	padding: 40px 0px 70px 110px;
	float: right;
	width:66%;
    z-index: 920;
	animation: fade 1.5s;
	@extend .cache-bottom-left;
	@include brk($t) { 
		padding: 60px 6% 50px;
		width: 100%; }
	@include brk($m) {
		padding: 55px 6% 0px;
		padding-left:$padding-mobile;
		padding-right:$padding-mobile;
		width: 100%;
	}
	.format {
		padding: 0px 0 50px 12%;
	    display: inline-block;
	    width: 100%;
	    @include brk ($tablet) {
	        padding: 0 9% 55px;
	    }
	    @include brk ($mobile) {
	        padding: 0 6%;
	        margin-top: 40px;
	        margin-bottom:40px;
	    }
	}
}









