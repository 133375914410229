



.page.lieu {

	.flex-image_texte, .flex-texte_image {
		.chapeau p {
			padding-left: 0;
			margin-bottom: 0px;
			&:before {
				left:-90px;
				width: 62px;
				top:20px;
				background:#ede4d6;
				@include brk($t) {
					display: none
				}
			}
		}
		.col-image {
			height:400px;
			@include brk($tp) {
				height:300px;
				margin-bottom: 30px;
			}
			@include brk($m) {
				height:250px;
				margin-bottom: 15px;
			}
		}
		.image {
			background-size: contain; 
			background-position: 50% 10%;
		}
	}



}


