

// LOADING BAR -------------------------------

#loading_bar {
    height:3px;
    width: 0%;
    position: fixed;
    top:0;
    left:0;
    right:0;
    z-index: 10000;
    transition: width 2s;
    background: transparentize($touche1, 0.1);
    @include brk($t) {
        background: transparentize(#fff, 0.3);
    }
    // &.home {
    //     background: transparentize(#000, 0.9);
    // }
    // &.page {
    //     // height:4px;
    //     background: transparentize(#000, 0.9);
    // }
}





// LOADING CIRCLE -------------------------------


.loader {
    position: absolute;
    width:100%;
    left:0;
    top:0;
    text-align: center;
    height:100%;
    // z-index:10;
    .loader-content {
        height:100%;
        width:100%;
        animation: fade 2s;
    }

    @keyframes loader_rotate{ 
        0% {transform: rotate(0deg);} 
        100% {transform: rotate(360deg);};
    }

    .loader-circle, .loader-line-mask {
        position: absolute;
        left: calc(50% - 60px);
        height: 120px;
        @include brk($m) {
            left: calc(50% - 50px);
            height: 100px;
        }
    }
        
    .loader-circle {
        width: 120px;
        border-radius: 50%;
        @include brk($m) {
            width: 100px;
        }
    }
    .loader-line-mask {
        width: 60px;
        overflow: hidden;
        transform-origin: 60px 60px;
        animation: loader_rotate 1.2s infinite linear;
        @include brk($m) {
            width: 50px;
            transform-origin: 50px 50px;
        }
    }
    .loader-line-mask .loader-line {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        @include brk($m) {
            width: 100px;
            height: 100px;
        }
    }
}

.loader-white {
    $loader01_light_color:  rgba(255, 255, 255, 0.08);
    $loader01_heavy_color:  rgba(255, 255, 255, 0.16);
    .loader-circle, .loader-line-mask {
        top: calc(50% - 60px);
        @include brk($m) {
            top: calc(50% - 50px);
        }
    }
    .loader-circle {box-shadow: inset 0 0 0 1px $loader01_light_color;}
    .loader-line-mask {mask-image: -webkit-linear-gradient(top, #000000, $loader01_light_color);}
    .loader-line-mask .loader-line {box-shadow: inset 0 0 0 1px $loader01_heavy_color;}
}

.loader-grey {
    $loader02_light_color:  rgba(0,0,0, 0.05);
    $loader02_heavy_color:  rgba(0,0,0, 0.10);
    .loader-circle, .loader-line-mask {
        top: calc(50% - 60px);
        @include brk($m) {
            top: calc(50% - 50px);
        }
    }
    .loader-circle {box-shadow: inset 0 0 0 1px $loader02_light_color;}
    .loader-line-mask {mask-image: -webkit-linear-gradient(top, #000000, $loader02_light_color);}
    .loader-line-mask .loader-line {box-shadow: inset 0 0 0 1px $loader02_heavy_color;}
}




















// WAITING EMAIL SPINNER -------------------------------

.spinner {
    width: 20px;
    height: 20px;
    top:50%;
    transform: translate(0,-50%);
    left: 15px;
    position: absolute;
    display:inline-block;
    overflow: visible;
}

.double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
    0%, 100% { 
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    } 50% { 
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}


