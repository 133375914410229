
// Order is respected
// Folders can be imported, using gulp-sass-bulk


@import "base/0__reset";
@import "base/1__breakpoint";
// @import "base/2__fontface";
@import "base/3__variables";
@import "base/5__classes";
@import "base/6__big-divs";
@import "base/7__animations";
@import "base/8__when-visible.scss";
@import "base/9__loaders";

@import "admin/front";

@import "C:/xampp/htdocs/OUDOUMONT/_PUBLIC/wp-content/themes/OUDOUMONT/__src/scss/vendors/_contactForm7.scss";
@import "C:/xampp/htdocs/OUDOUMONT/_PUBLIC/wp-content/themes/OUDOUMONT/__src/scss/vendors/_flexslider.scss";
@import "C:/xampp/htdocs/OUDOUMONT/_PUBLIC/wp-content/themes/OUDOUMONT/__src/scss/vendors/_fontface.scss";
@import "C:/xampp/htdocs/OUDOUMONT/_PUBLIC/wp-content/themes/OUDOUMONT/__src/scss/vendors/_justified-gallery.scss";
@import "C:/xampp/htdocs/OUDOUMONT/_PUBLIC/wp-content/themes/OUDOUMONT/__src/scss/vendors/_media-element.scss";
@import "C:/xampp/htdocs/OUDOUMONT/_PUBLIC/wp-content/themes/OUDOUMONT/__src/scss/vendors/_perfect-scrollbar.scss";
@import "C:/xampp/htdocs/OUDOUMONT/_PUBLIC/wp-content/themes/OUDOUMONT/__src/scss/vendors/_photoswipe-lightbox.scss";
@import "C:/xampp/htdocs/OUDOUMONT/_PUBLIC/wp-content/themes/OUDOUMONT/__src/scss/vendors/_search.scss";
@import "C:/xampp/htdocs/OUDOUMONT/_PUBLIC/wp-content/themes/OUDOUMONT/__src/scss/vendors/_selectize.bootstrap3.scss";
@import "C:/xampp/htdocs/OUDOUMONT/_PUBLIC/wp-content/themes/OUDOUMONT/__src/scss/vendors/_slick.scss";



@import "C:/xampp/htdocs/OUDOUMONT/_PUBLIC/wp-content/themes/OUDOUMONT/__src/scss/elements/_commun.scss";
@import "C:/xampp/htdocs/OUDOUMONT/_PUBLIC/wp-content/themes/OUDOUMONT/__src/scss/elements/_flexible.scss";
@import "C:/xampp/htdocs/OUDOUMONT/_PUBLIC/wp-content/themes/OUDOUMONT/__src/scss/elements/_footer.scss";
@import "C:/xampp/htdocs/OUDOUMONT/_PUBLIC/wp-content/themes/OUDOUMONT/__src/scss/elements/_hamburger.scss";
@import "C:/xampp/htdocs/OUDOUMONT/_PUBLIC/wp-content/themes/OUDOUMONT/__src/scss/elements/_header.scss";
@import "C:/xampp/htdocs/OUDOUMONT/_PUBLIC/wp-content/themes/OUDOUMONT/__src/scss/elements/_headroom.scss";
@import "C:/xampp/htdocs/OUDOUMONT/_PUBLIC/wp-content/themes/OUDOUMONT/__src/scss/elements/_mobile.scss";
@import "C:/xampp/htdocs/OUDOUMONT/_PUBLIC/wp-content/themes/OUDOUMONT/__src/scss/elements/_nav-horizontal.scss";
@import "C:/xampp/htdocs/OUDOUMONT/_PUBLIC/wp-content/themes/OUDOUMONT/__src/scss/elements/_nav-others.scss";
@import "C:/xampp/htdocs/OUDOUMONT/_PUBLIC/wp-content/themes/OUDOUMONT/__src/scss/elements/_nav-side.scss";

@import "C:/xampp/htdocs/OUDOUMONT/_PUBLIC/wp-content/themes/OUDOUMONT/__src/scss/pages/_front-page.scss";
@import "C:/xampp/htdocs/OUDOUMONT/_PUBLIC/wp-content/themes/OUDOUMONT/__src/scss/pages/_page-contact-form.scss";
@import "C:/xampp/htdocs/OUDOUMONT/_PUBLIC/wp-content/themes/OUDOUMONT/__src/scss/pages/_page-contact.scss";
@import "C:/xampp/htdocs/OUDOUMONT/_PUBLIC/wp-content/themes/OUDOUMONT/__src/scss/pages/_page-gdpr.scss";
@import "C:/xampp/htdocs/OUDOUMONT/_PUBLIC/wp-content/themes/OUDOUMONT/__src/scss/pages/_page-lieu.scss";
@import "C:/xampp/htdocs/OUDOUMONT/_PUBLIC/wp-content/themes/OUDOUMONT/__src/scss/pages/_page.scss";



