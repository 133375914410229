



.page.single {
    background:#fff;
}



// PAGE BANNER TOP

$page_wrapper-bannerTop-desktop: 528px;
$page_wrapper-bannerTop-laptop: 450px;
$page_wrapper-bannerTop-tablet: 660px;
$page_wrapper-bannerTop-mobile: 375px;



.page.single .banner.top {
    // background:$footer-color;
    transition: height 1s;
    height:$page_wrapper-bannerTop-desktop;
    @include brk($l) { height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { height:$page_wrapper-bannerTop-tablet; }
    @include brk($m) { height:$page_wrapper-bannerTop-mobile; }
}
.page.single .banner.top .bloc-container {
    transition: line-height 1.8s;
    line-height:$page_wrapper-bannerTop-desktop;
    @include brk($l) { line-height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { line-height:$page_wrapper-bannerTop-tablet; }
    @include brk($m) { line-height:$page_wrapper-bannerTop-mobile; }
}





.page.single .banner.top {
    position: relative;
    // background:transparentize(#2e2b26, 0);
    .image {
        background-position: 50% 85%;
        @include brk($m) {
            filter: brightness(50%);
        }
    }
    .bloc {
        text-align: center;
        @include brk($t) {
            padding: 50px 0 0 0;
        }
    }
    .sur-titre {
        animation: fade 2.5s;
        font-size: 25px;
        margin-bottom: 13px;
        color:#ffffff;
        font-style: italic;
        font-family: $family3;
        // text-transform: uppercase;
        letter-spacing: 2.5px;
        font-weight: $normal;
        position: relative;
        z-index: 4;
        @include brk($t) { 
        }
        @include brk($m) { 
            font-size: 9px; 
        }
    }
    .titre {
        animation: fade 1.5s;
        font-size:55px;
        line-height: 1.1;
        font-weight: $normal;
        font-family: $family2;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        color:#fff;
        user-select:none;
        position: relative;
        z-index: 4;
        @include brk($t) { font-size: 55px; }
        @include brk($m) { font-size: 25px; }
    }
    .sous-titre {
        animation: fade 2s;
        color:#fff;
        font-size: 25px;
        font-style: italic;
        margin-top: 20px;
        line-height: 1.4;
        font-family: $family2;
        @include brk($m) { font-size: 17px; }
    }
    .gradient {
        position: absolute;
        height:150px;
        top:auto;
        bottom:0;
        left:0;
        right:0;
        background: linear-gradient(to bottom, 
            transparentize(#fff, 1), 
            transparentize(#fff, 0.8) 30%, 
            transparentize(#fff, 0.15) 80%, 
            transparentize(#fff, 0)
        );
    }
}






.page.single {
    .banner.medium {
        background: #ffffff;
        padding-bottom:70px;
        padding-top:70px;
        @include brk($m) {
            padding-top:30px;
            padding-bottom:40px;
        }
        >.banner-content {
            padding:0;
            max-width: none;
        }
    }
}








// BANNER GALERIE -----------------------------------------------------


.banner.galerie {
    background:#ffffff;
    margin-top: 30px;
    margin-bottom: 20px;
    .banner-content {
        // padding:0;
    }
    .content {
    }
    .titre-section {
        text-align: center;
        font-size: 20px;
        color:#4a4337;
        font-weight: $regular;
        margin-bottom: 40px;
        font-family: $family2;
        text-transform: uppercase;
        letter-spacing: 6px;
        &:before, &:after {
            content: "";
            display: inline-block;
            width:17px;
            height:1px;
            margin: 0 13px;
            background:transparentize(#4a4337, 0.5);
            transform: translate3d(-2px,-6px,0);
        }
        @include brk($m) {
            font-size: 20px;
            margin-bottom: 35px;
        }
    }
    .sous-titre {
        text-align: center;
        font-size: 15.5px;
        font-weight: $light;
        line-height: 1.5;
        margin-bottom: 90px;
        @include brk($m) {
            font-size: 20px;
            margin-bottom: 35px;
        }
    }

    .justified {
        animation: fade 3s;
        #gallery {
            width:calc(100% + 10px);
            margin-left: -5px;
        }
        a {
            transition: filter 0.3s;
            cursor: pointer;
            filter:brightness(88%);
            .caption {display:none!important;}
            &:hover {
                filter:brightness(100%) contrast(105%);
            }
        }
    }
}












