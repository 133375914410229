

/* --------------------------------------------------------
	CONTACT FORM 7  -  CUSTOM SASS
---------------------------------------------------------*/



/**************  DIV GENERAL   ***************/

div.wpcf7 {
	min-height:200px;
	width:100%;
	position:relative;
	margin: 0 auto 0;
	padding:20px 80px;
}

form.wpcf7-form {
	min-height:200px;
	display:inline-block;
}




/**************  GENERAL FONT   ***************/

.wpcf7-form p {
	font-size: 17.5px;
	margin:0;
	padding:0;
    color:#313234;
    font-size:16px;
    letter-spacing: 1px;
    line-height: 1.5;
    position:relative;
	@include breakpoint($mobile) {}
}

div.wpcf7-response-output {
	font-size: 14px;
	font-style: italic;
	text-align: center;
}


/**************  CONTENU    ***************/

.wpc7-form-control-wrap {
}

.wpcf7 label {
	text-align: center;
	width:100%;
	display:inline-block;
	font-weight:$bold;
	margin-bottom:10px;
}

.wpcf7 input {
	height:85px;
	width:100%;
	margin-top:15px;
	letter-spacing:1.3px;
	border:none;
	border-bottom: solid 1px #bababa;
	text-align: center;
	display:inline-block;
	background:#fff;
	@include breakpoint($mobile) {}

}
.wpcf7 textarea {
	min-height:85px;
	height:85px;
	max-height:680px;
	width:100%;
	padding-top:37px;
	padding-bottom:12px;
	border:0;
	background:#fff;
	letter-spacing:1.3px;
	text-align: center;
	border-bottom: solid 1px #bababa;
	resize:none;
	display:inline-block!important;
	@include breakpoint($mobile) {}
}

	
.submit_button {
	margin-top:67px;
	padding:0;
    max-width:832px;
    height:70px;
    background:#beaf93;
    border:none;
    color:#fff;
    font-style: normal;
    font-family: 'Lato', sans-serif;
	font-size:14px;
	font-weight:400;
	letter-spacing: 3px;
	text-align:center!important;
	@include breakpoint($mobile) {}
	&:hover, &:focus {
		background:#a39375;
	}
}



.ajax-loader {
	display:none!important;
}



/**************  FILE UPLOAD    ***************/


div.uploadfieldtrick {
	display:inline-block;
	width:100%;
	&:hover input#uploadbrowsebutton{background:#777}
}


p#uploadFileNote {
	color:#bdbdbd;
	font-size:16.5px;
	width:72%;
	float:left;
	font-weight:400;
	line-height:2.7;
	cursor:pointer;
	@include breakpoint ($mobile) {width:100%;}

}
#fileuploadfield {
    display:none;
}

// BOUTON UPLOAD FILE
input#uploadbrowsebutton {
	width:25%;
	height:56px;
	margin-top:2px;
	text-align:center;
	padding:0;
	font-size:12.5px;
	color:$white;
	background:#bdbdbd;
	font-weight:400;
	letter-spacing:3px;
	float:right;
	cursor:pointer;
	border:none;
	padding-top:1px;
	transition: background-color 0.15s;
	&:hover {background:#777!important}
	@include breakpoint ($mobile) {display:none;}
}






/**************  REPONSES   ***************/


//Not valid popup boxes
span.wpcf7-not-valid-tip {
	color:#817388;
	display: inline-block;
	position:absolute;
	bottom:-2px;
	font-weight:normal;
	font-size:11px;
	width:200px;
	height:50px;
	height:20px;
	text-align:center;
	left:50%!important;
	margin-left:-100px!important;
	@include breakpoint($mobile) {
	}
}

.wpcf7-form p:nth-child(5) span.wpcf7-not-valid-tip {
	bottom:3px!important;
}

span.wpcf7-list-item {
	margin-left: 0.5em;
}

.wpcf7-display-none {
	display: none;
}

div.wpcf7 img.ajax-loader {
	border: none;
	vertical-align: middle;
	margin-left: 4px;
}

div.wpcf7 div.ajax-error {
	display: none;
}

div.wpcf7 .placeheld {
	color: #888;
}




// Bottom response output box

div.wpcf7-response-output {
	margin: 2em 0 1em;
	padding: 1em 1em;
	line-height:1.5;
	@include breakpoint($mobile) {
		font-size: 13px;
		padding: 1.1em 1em!important;
		line-height:1.7;
	}
}

div.wpcf7 .screen-reader-response {
	position: absolute;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	width: 1px;
	margin: 0;
	padding: 0;
	border: 0;
}

div.wpcf7-mail-sent-ok {
	border: none;
    height:50px;
}

div.wpcf7-mail-sent-ng {
	border: none;
}

div.wpcf7-spam-blocked {
	border: none;
}

div.wpcf7-validation-errors {
	border: none;

}




/**************  PLACEHOLDER ADJUST   ***************/
$placeholder_color: #beaf93;
$placeholder_font_weight: $bold;
$placeholder_text_align: center;
$placeholder_font_size: 15px;

::-webkit-input-placeholder { /* WebKit browsers */
    color: $placeholder_color;
    font-weight: $placeholder_font_weight;
    text-align: $placeholder_text_align;
    font-size: $placeholder_font_size;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color: $placeholder_color;
   font-weight: $placeholder_font_weight;
   text-align: $placeholder_text_align;
   font-size: $placeholder_font_size;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color: $placeholder_color;
   font-weight: $placeholder_font_weight;
   text-align: $placeholder_text_align;
   font-size: $placeholder_font_size;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
   color: $placeholder_color;
   font-weight: $placeholder_font_weight;
   text-align: $placeholder_text_align;
   font-size: $placeholder_font_size;
}


// style the placeholder class provided by jquery.placeholder.js
.placeholder{
  text-align: center;
  color: #beaf93;
  font-weight: normal;
  opacity: 1;
  padding-top:35px!important;
}


/**************  Glowing Input Highlights   ***************/

// input[type=text], textarea {
//   -webkit-transition: all 0.30s ease-in-out;
//   -moz-transition: all 0.30s ease-in-out;
//   -ms-transition: all 0.30s ease-in-out;
//   -o-transition: all 0.30s ease-in-out;
//   outline: none;
//   padding: 3px 0px 3px 3px;
//   margin: 5px 1px 3px 0px;
//   border: 1px solid #DDDDDD;
// }
 
// input[type=text]:focus, textarea:focus {
//   box-shadow: 0 0 5px rgba(81, 203, 238, 1);
//   padding: 3px 0px 3px 3px;
//   margin: 5px 1px 3px 0px;
//   border: 1px solid rgba(81, 203, 238, 1);
// }



