

@keyframes fade_left_menu_l1 {
    0% {opacity:0;letter-spacing: 2px;}
    10% {opacity:0;}
    10% {transform:translate3d(50px,0px,0px)}
    20% {opacity:0;}
    55% {transform:translate3d(0px,0px,0px);}
    80% {letter-spacing: 1px;}
    100% {opacity:1;}
}


@keyframes fade_left_menu_l2_p {
    0% {opacity:0;}
    10% {transform:translate3d(50px,0px,0px)}
    55% {transform:translate3d(0px,0px,0px);}
    100% {opacity:1;}
}

@keyframes fade_left_menu_l3_p {
    0% {opacity:0;}
    10% {transform:translate3d(50px,0px,0px)}
    55% {transform:translate3d(0px,0px,0px);}
    100% {opacity:1;}
}



@keyframes fade_scale {
    0% {opacity:0;transform:scale(0)}
    10% {opacity:0;}
    50% {transform:scale(1)}
    100% {opacity:1; }
}
@keyframes fade_scale_80 {
    0% {opacity:0;transform:scale(0.8)}
    10% {opacity:0;}
    70% {transform:scale(1)}
    100% {opacity:1; }
}
@keyframes fade_scale_90 {
    0% {opacity:0;transform:scale(0.9)}
    10% {opacity:0;}
    70% {transform:scale(1)}
    100% {opacity:1; }
}
@keyframes fade_scale_95 {
    0% {opacity:0;transform:scale(0.95)}
    10% {opacity:0;}
    70% {transform:scale(1)}
    100% {opacity:1; }
}





@keyframes fade {
    0% {opacity:0}
    100% {opacity:1}
}
@keyframes fade_95 {
    0% {opacity:0}
    100% {opacity: 0.95}
}
@keyframes fade_90 {
    0% {opacity:0}
    100% {opacity: 0.9}
}
@keyframes fade_80 {
    0% {opacity:0}
    100% {opacity: 0.8}
}
@keyframes fade_70 {
    0% {opacity:0}
    100% {opacity: 0.7}
}
@keyframes fade_60 {
    0% {opacity:0}
    100% {opacity: 0.6}
}
@keyframes fade_50 {
    0% {opacity:0}
    100% {opacity: 0.5}
}



@keyframes fadeOut {
    0% {opacity:1}
    100% {opacity:0}
}
@keyframes fadeOut_hide {
    0% {opacity:1;}
    99% {opacity:0;height:100%}
    100% {height:0}
}
@keyframes fadeOut_50_hide {
    0% {opacity:0.5;}
    99% {opacity:0;height:100%}
    100% {height:0}
}
@keyframes fadeOut_60_hide {
    0% {opacity:0.6;}
    99% {opacity:0;height:100%}
    100% {height:0}
}
@keyframes fadeOut_70_hide {
    0% {opacity:0.7;}
    99% {opacity:0;height:100%}
    100% {height:0}
}
@keyframes fadeOut_80_hide {
    0% {opacity:0.8;}
    99% {opacity:0;height:100%}
    100% {height:0}
}
@keyframes fadeOut_90_hide {
    0% {opacity:0.9;}
    99% {opacity:0;height:100%}
    100% {height:0}
}
@keyframes fadeOut_95_hide {
    0% {opacity:0.95;}
    99% {opacity:0;height:100%}
    100% {height:0}
}





@keyframes grow {
    0% {height:0}
    100% {height:100%}
}





@keyframes scale {
    0% {transform:scale(0); opacity:0}
    100% {transform:scale(1); opacity:1}
}
@keyframes scale_50 {
    0% {transform:scale(0.5); opacity:0}
    100% {transform:scale(1); opacity:1}
}
@keyframes scale_80 {
    0% {transform:scale(0.8); opacity:0}
    100% {transform:scale(1); opacity:1}
}
@keyframes scale_90 {
    0% {transform:scale(0.9); opacity:0}
    100% {transform:scale(1); opacity:1}
}
@keyframes scale_95 {
    0% {transform:scale(0.95); opacity:0}
    100% {transform:scale(1); opacity:1}
}





@keyframes move_left {
    0% {transform:translate3d(100px,0px,0px)}
    100% {transform:translate3d(0px,0px,0px)}
}






@keyframes move_up_15 {
    0% {transform:translateY(15px)}
    100% {transform:translateY(0px)}
}

@keyframes move_up_10 {
    0% {transform:translateY(10px)}
    100% {transform:translateY(0px)}
}

@keyframes move_up_5 {
    0% {transform:translateY(5px)}
    100% {transform:translateY(0px)}
}









@keyframes fade_move {
    0% {opacity:0; transform:translateY(15px)}
    100% {opacity:1; transform:translateY(0px)}
}