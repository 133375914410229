



	.mejs-offscreen, .mejs-clear, .mejs-layers, .mejs-controls {
		display:none!important;
	}
	.mejs-container {
		width:100%!important;
		@include breakpoint($tablet) {
			display: none!important;
		}
		.mejs-inner{
			height:100%;
			.mejs-mediaelement {
				height:100%;
				position: relative;
				
				// overlay to disable click on video
				&::after {
					content:"";
					position: absolute;
					// background: green;
					right:0;
					height:100%;
					width:100%;
					top:0;
				}
				video.mejs-description {
					min-width:100%;
					// min-height:100%;
					cursor:pointer;
				}
			}
		}
	}