

// FORM BACKGROUND 
$contact_form_background: #e6e6e6;
$input_text_color: #555868;


// FONT-SIZES
$input_fontsize-desktop: 15.5px;
$input_fontsize-tablet: 15.5px;
$input_fontsize-mobile: 15.5px;



// PADDING-LEFT
$field_padding_left: 44px !important;


.contact-form {
    margin-top: 0px;
    margin-bottom:100px;
    display: inline-block;
    width: 100%;
    @include brk($t) {
        margin-bottom:80px;
        padding:0;
    }
    @include brk($m) {
        margin-bottom:50px;
    }
    .sur-titre {
        font-size: 17.5px;
        font-style: italic;
        font-weight: $normal;
        font-family: $family3;
        padding-bottom:5px;
        color:#4f4630;
        margin-top: 15px;
        @include brk($mobile) {
            padding-right: 20%;
            line-height: 1.2;
            padding-bottom:15px;
        }
    }
    .titre-section {
        font-size:40px;
        font-weight: $normal;
        font-family: $family2;
        color:#4f4630;
        letter-spacing: 2px;
        text-transform: uppercase;
        line-height: 1.2;
        animation: fade 1.5s;
        position: relative;
        margin: 30px 0 45px;
        @include brk($mobile) {
            font-size:30px;
        }
    }
    form {
        width:100%;
        max-width: 900px;
        min-height:280px;
        margin-top: 0px;
        margin-bottom: 45px;
        clear: both;
        @extend .clearfix;
        @include brk($t) {
            max-width: none;
        }
        @include brk($m) {
        }

        $container_mb-desktop: 30px;
        .container {
            display: inline-block;
            width:100%;
            margin-bottom: 30px;
            position: relative;
            transition: margin 0.5s;
            @include brk($m) { margin-bottom: 10px; }
            @for $i from 1 through 20 {
                &:nth-child(#{$i}) { 
                    animation-delay: ((0.06s * $i) - 0.05s);
                }
            }
            &.focus-submitted.make-room {
                margin-top:30px;
            }
            

            label {
                font-size: 14px;
                font-weight: $bold;
                font-family: $f1;
                color:$touche3;
                letter-spacing: 1px;
                display: inline-block;
                margin-bottom:20px;
                // display: none!important;
            }
            input, textarea { 
                position: relative;
                user-select: text; 
            }

            // FONT -----------------------------
            input, textarea, select, 
            .selectize-input .item, .selectize-dropdown .option {
                font-weight: $bold;
                font-family: $f2;
                color:$input_text_color;
                letter-spacing: 1px;
            }

            .selectize-input .item { padding-top:15px; }
            .option {
                font-size: 14.5px;
                font-weight: $normal;
                color:$touche_de_couleur2;
                padding: 17px 21px;
            }
            
            $box_shadow: 1px 1px 4px 0px transparentize(#000, 0.95);
            input, textarea, select, .selectize-input {
                width:100%;
                z-index: 0;
                padding:0 20px;
                transition: border 0.5s, box-shadow 0.15s, padding-left 0.3s;
                border:none;
                border:1px solid transparentize(#000, 0.9);
                border-radius:12px;
                background:#fff !important;
                box-shadow: $box_shadow;
                position: relative;
                // text-transform: uppercase;
                &.ng-pristine {
                    border:1px solid #fff!important;
                }
                &.focus, &:focus {
                    // border:1px solid transparentize($touche2, 0.8);
                    box-shadow: 2px 2px 4px 1px transparentize($touche2, 0.81) !important;
                }
            }

            input, select, .selectize-input {
                height:54px;
                line-height:54px;
                @include brk($m) { 
                    height:50px; 
                    line-height:50px;
                }
            }   

            input {
                font-size: $input_fontsize-desktop;
                will-change: box-shadow, background, padding-left;
                @include brk($td) { font-size: $input_fontsize-tablet; }
                @include brk($m) { font-size: $input_fontsize-mobile; }
            }


            // FIX FOR CHROME AUTOFILL
            input, textarea {
                &:-webkit-autofill {
                    -webkit-box-shadow: 0 0 0px 1000px #fff inset, $box_shadow !important;
                    -webkit-text-fill-color: $input_text_color !important;
                    border: 1px solid #fff;
                    transition: border 0s;
                    &:focus {
                    }
                }
            }

            // Turn Off Number Input Spinners
            input[type=number]::-webkit-inner-spin-button, 
            input[type=number]::-webkit-outer-spin-button { 
                -webkit-appearance: none; 
                margin: 0; 
            }

            $form_half_width: calc(50% - (#{$container_mb-desktop} / 2));
            &.left {width:$form_half_width;float:left;}
            &.right {width:$form_half_width;float:right;}
            &.full {width:100%; float:left}
            &.clear {clear:both}
            @include brk($m) {
                width:100% !important;
                float: none!important;
                clear: both;
            }

            &.email-verif {
                display: none;
                &.active {
                    animation: fade 0.5s;
                    display: inline-block;
                }
            }
        }
        legend {
            font-weight: $bold;
            font-family: $f2;
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 1px;
            margin-top: 30px;
            margin-bottom: 30px;
            clear:both;
            float: left;
            width:100%;
            position: relative;
            &::before {
                content:"";
                display: inline-block;
                position: absolute;
                top:8px;
                left:-39px;
                height:1px;
                width:21px;
                transition: opacity 0.5s, width 0.2s;
                background: darken($touche1, 0%);
                @include brk($tp) {display:none}
            }
        }
        textarea {
            font-size: $input_fontsize-desktop;
            resize:none!important;
            padding-top:30px!important;
            padding-right: $field_padding_left;
            text-align: justify;
            line-height: 1.5;
            height:auto;
            will-change: box-shadow, background, height, padding-left;
            transition: border 0.3s, height 0.5s, min-height 0.5s, padding-left 0.3s !important;
            max-height:500px;
            min-height: 150px;
            &:focus { min-height: 170px; } 
            @include brk($td) { font-size: $input_fontsize-tablet; }
            @include brk($m) { 
                font-size: $input_fontsize-mobile; 
                text-align: left;
            }
        }
        .container.message {
            margin-bottom: 15px;
        }



        /**************  PLACEHOLDER   ***************/
        input::placeholder, textarea::placeholder {
            
            font-size: $input_fontsize-desktop;
            font-weight: $normal;
            color: transparentize($input_text_color, 0.2) !important;
            text-align: left;
            letter-spacing: 0.5px;
            display: none;
            // line-height: 1.3;
            opacity: 0;
            // transition: opacity 0.3s;
            @include brk($td){ font-size: $input_fontsize-tablet; }
            @include brk($m){  font-size: $input_fontsize-mobile; }
        }
        input:focus, textarea:focus {
            &::placeholder { opacity: 0.35!important; }
        }



        .champs-requis {
            text-align: right;
            margin-bottom: 0px;
            float: right;
            p {
                font-size: 14px;
                font-weight: $light;
                font-style: italic;
                color:$touche1;
                user-select: none;
                @include brk($m) {
                    font-size: 11px;
                }
            }
            // @include brk($m) {display:none;}
        }

        button[type="submit"] {
            width:250px;
            text-align: center;
            background:#fff;
            font-weight: $black;
            margin-top: 30px;
            border: 1px solid transparentize(#000, 0.5);
            padding: 0 0 0 0px;
            margin-top: 30px;
            overflow:hidden;
            position: relative;
            @include brk($m) {
                width:100%;
                float: none;
            }
            p {
                text-transform: uppercase;
                font-family: $family2;
                font-weight: $black;
                padding:15px 20px;
                font-size:10px;
                letter-spacing: 2px;
                color:#2e2b26;
                user-select:none;
            }
            .icon {
                color:#fff;
                font-size:25px;
                font-weight: $normal;
                user-select:none;
                position: absolute;
                top:10px;
                right:0;
                display: none;
                &::before {
                    content:"›";
                    height: 29px;
                    display: inline-block;
                    line-height:1.05;
                    padding:0 20px;
                    border-left:1px solid transparentize(#fff, 0.65);
                }
            }
            &:not(.sent):hover, &:not(.sent):focus {
                background: $touche_de_couleur2;
                p {
                    color:#fff;
                }
            }
            &.wait {
                transition: background 0.2s;
                background: darken(#fff, 70%);
                .icon.envoyer,
                p.envoyer {display:none}
                p.wait { animation: fade 1s; }
            }
            &.sent {
                transition: background 0.2s;
                background: desaturate(green, 0%)!important;
                border: 1px solid desaturate(green, 0%);
                p.wait { display:none }
                p.sent {
                    color:#fff;
                    animation: fade 1s;
                }
            }
            .waiting.spinner {
            }
        }
    }
}


















// // INPUT SELECT  (Selectize) --------------------------------


// .contact-form {
//     .container.select {
//         height:auto;
//         transition: margin-top 0.5s;
//         // margin-bottom: 35px;
//         // @include brk($m) { margin-bottom: 30px; }
//         label { margin-top: 15px; }
//         &.focus-submitted {
//             margin-top:40px;
//         }
//         .selectize-control {
//             // width:calc(100% - 115px);
//             // float: right;
//             @include brk($m) {
//                 float: none;
//                 width:100%;
//             }
//             .selectize-input {
//                 height:auto;
//                 min-height:54px;
//                 background:#4f009d !important;
//                 padding:0;
//                 position: relative;
//                 .item {
//                     user-select:none;
//                     padding:18px 50px 18px 21px;
//                     color:#fff;
//                     cursor:pointer;
//                     font-size:15px;
//                     @include brk($m) {
//                         font-size:13px;
//                     }
//                     &:hover {
//                         background:darken(#4f009d, 10%);
//                     }
//                 }
//                 input {
//                     // display:none !important;
//                     background:none!important;
//                     border:none!important;
//                     color:#fff;
//                     width: 100%!important;
//                     cursor:pointer;
//                     &::placeholder {
//                         transition: color 0.3s;
//                         color: transparentize(#fff, 0.1) !important;
//                     }
//                     &:hover::placeholder {
//                         color: transparentize(#fff, 0) !important;
//                     }
//                     &:focus {
//                         &::placeholder { opacity: 0.35!important; }
//                     }
//                 }
//                 &.has-items {
//                     input {display:none!important}
//                 }
//                 &::after {
//                     content:"";
//                     height:22px;
//                     width:22px;
//                     border:none;
//                     top:18px;
//                     right:30px;
//                     margin-top: 0;
//                     display:inline-block;
//                     position: absolute;
//                     top:17px;
//                     right:20px;
//                     background:url('../assets/images/select-dropdown.svg');
//                     // background:green;
//                 }
//             }
//             .selectize-dropdown {
//                 overflow: hidden;
//                 border-radius:0px;
//                 margin-top: 0;
//                 position: absolute;

//                 .selectize-dropdown-content { 
//                     padding: 0px 0px; 
//                     background:#4f009d;
//                     .create {display:none}
//                     .option {
//                         color:#fff !important;
//                         cursor:pointer;
//                         font-size:15px;
//                         padding: 14px 40px 14px 21px;
//                         @include brk($m) {
//                             font-size:13px;
//                             padding: 9px 40px 9px 21px;
//                         }
//                         &:first-child {padding-top:18px;}
//                         &:last-child { padding-bottom:18px; }
//                         &.selected, &:hover {
//                             background:darken(#4f009d, 10%);
//                         }
//                         @for $i from 1 through 20 {
//                             &:nth-child(#{$i}) { 
//                                 animation-delay: (( 0.02s * $i) - 0.02s);
//                                 animation-duration: 0.3s;
//                                 animation-name:fade;
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     } 
// }








// INPUT SELECT  (Selectize) --------------------------------


.contact-form {
    .container.reason {
        // margin-bottom: 35px;
        // @include brk($m) { margin-bottom: 30px; }
        label { margin-top: 15px; }
        .selectize-control {
            // width:calc(100% - 115px);
            // float: right;
            @include brk($m) {
                float: none;
                width:100%;
            }
            .selectize-input {
                input {display:none !important;}
                &::after {
                    content:"";
                    height:22px;
                    width:22px;
                    display: inline-block;
                    position: absolute;
                    border:none;
                    top:18px;
                    right:30px;
                    margin-top: 0;
                    background:url('../assets/images/select-dropdown.svg');
                }
            }
            .selectize-dropdown {
                margin-top: 0px;
                border-radius:10px;
                overflow: hidden;
                .selectize-dropdown-content {padding:0;}
            }
        }
    } 
}






// FICHIERS --------------------------------------------


.contact-form .container.files {
    margin-bottom: 0;
    display: inline-block;
    width:100%;
    position: relative;
    min-height:10px;
    // border:1px solid transparentize($touche1, 0);
    // width: calc(100% - 60px);
    input {
        height:70px;
        width:100%;
        line-height: 2;
        transition: all 0.30s ease-in-out;
        @include brk ($mobile){
            height:41px;
        }
    }
    input.file::-webkit-file-upload-button {
        visibility: hidden;
    }
    input[type="file"] {
        display: none;
    }
    label, .attacher-vos-docs {
        display: inline-block;
        width:100%;
        color:#797979;
        font-weight: $light;
        padding-right:80px;
        user-select:none;
        @include brk($td) { font-size: $input_fontsize-tablet; }
        @include brk($m) { font-size: $input_fontsize-mobile; }
    }
    .icon-container {
        display: inline-block;
        width:40px;
        height:40px;
        background:$touche1;
        border-radius:50%;
        position: absolute;
        right:0px;
        top:15px;
        text-align: center;
        transform: scale(1.1);
        transition: transform 0.3s;
        animation: fadeIn 0.5s;
        @include brk ($t){ top:10px; }
        @include brk ($m){ 
            top:5px; 
            width:34px;
            height:34px;
        }
        .icon-plus {
            width:18px;
            height:18px;
            display:inline-block;
            margin-top: 11px;
            @include brk ($m){ margin-top: 9px; }
        }
        svg {
            transition: transform 0.3s;
            transform: scale(0.6);
        }
        .st0{fill:none;stroke:#FFFFFF;stroke-width:5;stroke-miterlimit:10;}
    }
    &.adding-disabled {
        .attacher-vos-docs {cursor: default}
        .icon-container {
            // animation: fadeOut 0.5s; 
            cursor: default;
            transform: scale(0);
        }
    }
    &.disabled {
        .attacher-vos-docs {user-select:none;}
        button {cursor: default}
    }
    &:not(.disabled) {
        label, .attacher-vos-docs {
            cursor: pointer;
            &:hover .icon-container {
                background:darken($touche1,15%);
                transform: scale(1);
            }
        }
        .icon-container {
            cursor: pointer;
            &:hover {
                background:darken($touche1,15%);
                transform: scale(1);
            }
        }
        .ul-fichiers {
            li {
                cursor: pointer;
                &:hover {
                    .icon-bin {
                        transform: scale(1);
                        transition: background 0.4s, transform 0.5s;
                        background: desaturate(red, 30%);
                    }
                    p {color:#000;}
                }
            }
            p {cursor: pointer;}
        }
    }
    .ul-fichiers {
        margin-bottom: 15px;
        li {
            display: inline-block;
            width:calc(100% - 45px);
            padding-top: 10px;
            padding-bottom: 10px;
            margin-top: -4px;
            @include brk($t) {width:calc(100% - 10px);}
            &.ng-enter {
                @for $i from 1 through 5 {
                &:nth-child(#{$i}) { 
                    transition-delay: 0s + (0.04s * $i) - 0.04s;
                }
                }
                transition: opacity 0.7s;
                opacity: 0;
                &.ng-enter-active {
                    opacity: 1;
                }
            }
        }
        p {
            padding: 0;
            text-align: left;
            transition: color 0.5s;
            padding: 0px;
            font-size: 12px;
            letter-spacing: 0px;
            font-weight: $light;
            line-height: 1.6;
            user-select:none;
        }
        .fichier-nom {
            float: left;
            width: calc(100% - 130px);
            overflow: hidden;
            position: relative;
        }
        .fichier-taille { float: right;}
        button {
            float: right;
            padding: 0 0 0 20px;
            .icon-bin {
                transform: scale(0.8);
                margin-top: -3px;
                background: #bbb;
                transition: background 0.3s, transform 0.5s;
                width:23px;
                height:24px;
                border-radius:50%;
                @include brk($td) {
                    background: desaturate(red, 30%);
                    transform: scale(0.84);
                }
                svg {transform: scale(0.4);}
                .st0{fill:none;stroke:#FFFFFF;stroke-width:5;stroke-miterlimit:10;}
            }
        }
    }
}



.contact-form #progress_bar {
    width:calc(100% - 50px);
    height:1px;
    background:transparentize(#000, 0.9);
    margin-bottom: 5px;
    transition: width 1.5s;
    @include brk($t) {width:calc(100% - 10px);}
    .actual-progress {
        height:100%;
        background:#2a8128;
        width:0%;
        transition:width 0.3s;
    }
}






$response_box_height: 50px;
.contact-form .response_box-container {
    position: relative;
    // overflow: hidden;
    height:$response_box_height;
    padding-top:50px;
    // background: #eee;
    &.ng-enter {
        transition: opacity 1.5s, height 1s; 
        height:0; 
        opacity:0; 
        &.ng-enter-active {
            opacity:1; 
            height:$response_box_height; 
        }
    }
    &.ng-leave {
        transition: opacity 2.5s, height 2.5s; 
        height:$response_box_height; 
        opacity:1; 
        &.ng-leave-active {
            opacity:0; 
            height:0px; 
        }
    }
    .response_box {
        text-align:center;
        display: inline-block;
        position: absolute;
        top:$response_box_height - 10px;
        left:0;
        font-size: 14px;
        width:100%;
        letter-spacing: 0px;
        font-style: italic;
        &.please-wait {color:#333}
        &.success {color:green}
        &.error {color:red}
    }
}





















// TOOLTIP   ---------------------------------

$tooltip_red: mix(red, #b46c6b, 28%);
$tooltip_orange: mix(orange, #dbb36d, 15%);

@keyframes show_tooltip {
    0% {z-index:1; transform: translateY(30px)}
    50% {z-index:1; transform: translateY(-1px)}
    51% {z-index:20}
    100% {z-index:20;  transform: translateY(0px)}
}
@keyframes show_tooltip_triangle {
    0% {transform: translateY(-20px)}
    20% {transform: translateY(-20px)}
    100% {transform: translateY(0px)}
}

.contact-form form.ng-submitted {
    input.focus-submitted + .tooltip,
    div.container.select.focus-submitted .tooltip,
    div.radio-container.focus-submitted .tooltip,
    div#gdpr_container.focus-submitted .tooltip,
    textarea.focus-submitted + .tooltip {display:inline-block;}
}

.contact-form form .tooltip {
    position: absolute;
    top:-5px;
    left:19px;
    will-change: transform, opacity;
    box-shadow: 0px 0px 4px 1px transparentize(#000, 0.91) !important;
    transform: translate3d(0,0,0);
    display:none;
    animation-fill-mode: both !important;
    animation: show_tooltip 0.25s;
    span {
        padding:15px 19px;
        display: inline-block;
        color:#fff;
        font-size: 12px;
        animation: fade 0.2s;
        cursor:default;
        &::first-letter { text-transform: uppercase; }
    }
    >div {
        // border-top-right-radius:2px;
        // border-top-left-radius:2px;
    }
    >div::after {
        content:"";
        transform: translate3d(0,0,0);
        animation: show_tooltip_triangle 0.3s;
        position: absolute;
        left:0;
        bottom:-9px;
        width: 0; 
        height: 0; 
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid green;
    }
    .files-missing,
    .email-verif-error,
    [ng-message="required"] {
        background: $tooltip_red;
        &::after {border-left-color:$tooltip_red;}
    }
    [ng-message="pattern"], [ng-message="email"] {
        background: $tooltip_orange;
        &::after {border-left-color:$tooltip_orange;}
    }
}





// BACKGROUND CHANGE -----------------------------------

.contact-form form.ng-submitted {
    input.ng-invalid,
    textarea.ng-invalid {
        // border-bottom:1px solid $tooltip_red !important;
        background:mix($contact_form_background, $tooltip_red, 91%)!important;
    }
    input.ng-invalid-pattern,
    textarea.ng-invalid-pattern {
        // border-bottom:1px solid $tooltip_orange !important;
        background:mix($contact_form_background, $tooltip_orange, 85%)!important;
    }
}












// REQUIRED ICONS ---------------------------------


.contact-form form {
    .container {
        &.input .icon {
            height:100%; 
        }
        .icon {
            position: absolute;
            z-index: 20;
            top:19.5px;
            will-change: opacity, transform;
            &.ng-enter {
                opacity:0;
                transform:scale(0);
                transition: opacity 0.25s 0.05s, transform 0.2s 0.05s;
                &.ng-enter-active {
                    transform:scale(1);
                    opacity:1; 
                }
            }
        }
        .icon-checked {
            width:14px;
            left:19px;
            * {fill:transparentize(green, 0)}
        }
        .icon-circle {
            width:9px;
            left:20px;
            * {fill:mix(orange, $tooltip_orange, 90%)}
        }
        .icon-cross {
            width:10px;
            left:19px;
            * {
                fill:none;
                stroke:mix(red, $tooltip_red, 60%);
                stroke-width:9;
                stroke-miterlimit:10;
            }
        }
        &.textarea {
            .icon { height: 23px; }
            .icon-checked {top: 58.5px;}
            .icon-circle, .icon-cross { top: 57.5px;}
        }
    }

    input {
        &.prefill,
        &.ng-invalid.cross-has-shown,
        &.ng-invalid-pattern.ng-dirty.blured,
        &.ng-invalid-pattern.ng-dirty.blured,
        &.ng-valid.blured.ng-dirty,
        &[disabled="disabled"] {
            padding-left: $field_padding_left;
        }
    }

    textarea {
        &.ng-valid:not(.ng-empty), &.cross-has-shown {
            padding-left: $field_padding_left;
        }
    }

    .not-required-input {
        // input[ng-required="false"].ng-empty {padding-left:20px !important}
        // .tooltip.not-required-input + .icon-checked{display:none!important}
    }

    .not-required-textarea {
        textarea[ng-required="false"] {padding-left:0 !important}
        .icon-checked {display:none!important}
    }

    &.ng-submitted {
        input, textarea {
            &.ng-invalid-pattern {
                padding-left: $field_padding_left;
            }
            &[type="tel"], &[type="email"] {
                &.ng-valid {
                    padding-left: $field_padding_left;
                }
            }
        }
    }
}





















// WHEN FORM IS COMPLETE & SUBMITTED  ->  DISABLED -------------------

$disabled_color: transparentize($input_text_color, 0.75) !important;

.contact-form form[disabled="disabled"] {
    .container input, .container textarea {
        color: $disabled_color;
        user-select:none;
        cursor: default;
        padding-left: $field_padding_left;
        &:disabled:-webkit-autofill {
            -webkit-text-fill-color: $disabled_color;
        }
    }
}



 
















div.gdpr-container {
    width: 100%;
    display:inline-block;
    margin:60px auto 20px !important;
    position: relative;
    @include brk($td) {
        width: 70%;
    }
    @include brk($tp) {
        width: 100%;
    }
    @include brk($m) {
    }
    label[for="gdpr"] {
    }
    .tooltip.gdpr {
        left:35px;
        top: -55px;
    }
    p.note {
        padding-left:40px;
        user-select:none;
        z-index: 11;
        font-family: $family2;
        @include brk($m) { 
            padding-left:40px;
        }
        a {
            font-family: $family2;
            -webkit-text-stroke: #fff;
            text-decoration: underline;
        }
        &, a {
           font-size:13px; 
           @include brk($m) { 
                font-size: 11px;
            }
        }
    }
    a:hover {
        color:$touche1;
    }
    input[type="checkbox"] {
        display: none;
        + label {
            .case {
                cursor:pointer;
                width:26px;
                height:26px;
                border-radius:50%;
                text-align: center;
                position: absolute;
                z-index: 11;
                left:0px;
                top:-3px;
                border: 1px solid transparentize(#000, 0.85);
                padding:7px;
                background:#fff;
                @include brk($m) {
                    top:-4px;
                }
                &:hover {
                    background: transparentize(#000, 0.96);
                    border: 1px solid transparent;
                };
            }
            .case span {
                background: $touche2;
                display: block;
                height:100%;
                border-radius:50%;
                transition: transform 0.2s;
                transform: scale(0);
            }
        }
        &:checked + label {
            .case span { 
                transition: transform 0.15s;
                transform: scale(1);
            }
        }
    }
}


















.contact-form {
    margin-top: 0px;
    margin-bottom:100px;
    padding: 0 0% 0 0;
    display: inline-block;
    width: 100%;
    min-height:800px;
    // overflow:hidden;
    @include brk($tablet) {
        padding:0;
    }
    
    
    form {
        width:100%;
        // max-width: 900px;
        margin-top: 25px;
        min-height:280px;
        margin-bottom: 45px;
        @include brk($tablet) {
            margin-top: 55px;
            max-width: none;
        }
        @include brk($mobile) {margin-top: 50px;}
        .container {
            display: inline-block;
            width:100%;
            margin-bottom: 30px;
            label {
                display: inline-block;
                font-size: 16px;
                margin-bottom:20px;
                letter-spacing: 0px;
                color:#2e2b26;
                font-weight: $regular;
                font-family: $family2;
            }
            input, textarea { user-select: text; }

            // FONT -----------------------------
            input, textarea, select, .selectize-input .item, .option {
                letter-spacing: 1px;
                font-size: 15.5px;
                font-weight: $bold;
                color:$touche_de_couleur2;
                font-family: $family2;
            }
            .selectize-input .item { padding-top:15px; }
            .option {
                font-size: 14.5px;
                font-weight: $normal;
                color:$touche_de_couleur2;
                padding: 17px 21px;
            }

            // BACKGROUND --------------------------
            input, textarea, select, .selectize-input {
                height:57px;
                width:100%;
                padding:0 20px;
                transition: border 0.5s, box-shadow 0.15s;
                border:1px solid #f7f6f5;
                border-radius:12px;
                background:#f7f6f5 !important;
                &.ng-pristine {
                    border:1px solid #f7f6f5!important;
                }
                &.focus, &:focus {
                    // border:1px solid transparentize($touche_de_couleur2, 0.8);
                    box-shadow: 2px 2px 4px 1px transparentize($touche_de_couleur2, 0.81) !important;
                }
            }
            // FIX FOR CHROME AUTOFILL
            input:-webkit-autofill {
                -webkit-box-shadow: 0 0 0px 1000px #fff inset;
                -webkit-text-fill-color: rgba(0,0,0,0.9) !important;
                border: 1px solid rgba(0,0,0,0.2);
                &:focus {
                    border:1px solid rgba(0,0,0,0.4);
                }
            }
            &.email, &.city {width:46.5%;float:left;}
            &.phone, &.postcode {width:46.5%;float:right;}
            @include breakpoint ($mobile) {
                &.email, &.city {width:100%;}
                &.phone, &.postcode {width:100%;}
            }
        }
        textarea {
            resize:none!important;
            padding-top:20px!important;
            box-shadow:none;
            min-height: 220px;
            height:auto;
            transition: border 0.5s, height 0.5s !important;
            // width: 100%;
            // display: inline-block;
            max-height:500px;
            transition: height 1s;
            // line-height: 2;
        }
        .container.message {
            margin-bottom: 18px;
        }
        .required {
            text-align: right;
            color:$touche_de_couleur;
            font-weight: $light;
            font-style: italic;
            margin-bottom: 20px;
            font-size: 14px;
        }
       
    }
}









// FICHIERS --------------------------------------------


.contact-form .container.files {
    margin-bottom: 0;
    display: inline-block;
    width:100%;
    position: relative;
    min-height:10px;
    // border-bottom:1px solid rgba(0,0,0,0.10);
    // width: calc(100% - 60px);
    input {
        height:70px;
        width:100%;
        line-height: 2;
        transition: all 0.30s ease-in-out;
        @include brk ($mobile){
            height:41px;
        }
    }
    input.file::-webkit-file-upload-button {
        visibility: hidden;
    }
    input[type="file"] {
        display: none;
    }
    label, .attacher-vos-docs {
        cursor: pointer;
        display: inline-block;
        padding-top:10px;
        padding-bottom:25px;
        line-height: 1.5;
        width:100%;
        color:#2e2b26;
        font-weight: $light;
        font-family: $family2;
        padding-right:80px;
        &:hover + .icon-container {
            background:darken($touche_de_couleur,15%);
            transform: scale(1);
        }
    }
    .icon-container {
        display: inline-block;
        width:40px;
        height:40px;
        background:#4f4630;
        border-radius:50%;
        position: absolute;
        right:0px;
        top:71px;
        text-align: center;
        cursor: pointer;
        transform: scale(1.1);
        transition: transform 0.3s;
        @include brk ($t){ top:76px; }
        @include brk ($m){ top:76px; }
        .icon {
            width:18px;
            height:18px;
            margin-top: 11px;
        }
        svg {
            transition: transform 0.3s;
            transform: scale(0.6);
        }
        .st0{fill:none;stroke:#FFFFFF;stroke-width:5;stroke-miterlimit:10;}
        &:hover {
            background:darken($touche_de_couleur,15%);
            transform: scale(1);
        }
    }
    .ul-fichiers {
        margin-bottom: 15px;
        li {
            display: inline-block;
            width:calc(100% - 45px);
            padding-top: 10px;
            padding-bottom: 10px;
            cursor: pointer;
            margin-top: -4px;
            @include brk($t) {width:calc(100% - 10px);}
            &:nth-child(1) {margin-top: 20px;}
            &.ng-enter {
                @for $i from 1 through 50 {
                &:nth-child(#{$i}) { 
                    transition-delay: 0s + (0.04s * $i) - 0.04s;
                }
                }
                transition: opacity 0.7s;
                opacity: 0;
                &.ng-enter-active {
                    opacity: 1;
                }
            }
            &:hover {
                .icon-bin {
                    transform: scale(1);
                    transition: background 0.4s, transform 0.5s;
                    background: desaturate(red, 30%);
                }
                p {color:#000;}
            }
        }
        p {
            padding: 0;
            cursor: pointer;
            text-align: left;
            transition: color 0.5s;
            padding: 0px;
            font-size: 12px;
            letter-spacing: 0px;
            font-weight: $light;
            line-height: 1.6;
            user-select:none;
        }
        .fichier-nom {
            float: left;
            width: calc(100% - 130px);
            overflow: hidden;
            position: relative;
        }
        .fichier-taille { float: right;}
        button {
            float: right;
            padding: 0 0 0 20px;
            .icon-bin {
                transform: scale(0.8);
                margin-top: -3px;
                background: #bbb;
                transition: background 0.3s, transform 0.5s;
                width:23px;
                height:24px;
                border-radius:50%;
                @include brk($td) {
                    background: desaturate(red, 30%);
                    transform: scale(0.84);
                }
                svg {transform: scale(0.4);}
                .st0{fill:none;stroke:#FFFFFF;stroke-width:5;stroke-miterlimit:10;}
            }
        }
    }
}



// #progress_bar {
//     width:calc(100% - 50px);
//     height:1px;
//     background:rgba(0,0,0,0.10);
//     margin-bottom: 23px;
//     transition: width 1.5s;
//     @include brk($t) {width:calc(100% - 10px);}
//     .actual-progress {
//         height:100%;
//         background:#2a8128;
//         width:0%;
//         transition:width 0.3s;
//     }
// }








// .response_box-container {
//     position: relative;
//     overflow: hidden;
//     height:40px;
//     // background: #eee;
//     &.ng-enter {
//         transition: opacity 1.5s, height 1s; 
//         height:0; 
//         opacity:0; 
//         &.ng-enter-active {
//             opacity:1; 
//             height:40px; 
//         }
//     }
//     &.ng-leave {
//         transition: opacity 2.5s, height 2.5s; 
//         height:40px; 
//         opacity:1; 
//         &.ng-leave-active {
//             opacity:0; 
//             height:0px; 
//         }
//     }
//     .response_box {
//         display: inline-block;
//         position: absolute;
//         top:0;
//         left:0;
//         font-size: 14px;
//         width:100%;
//         letter-spacing: 0px;
//         font-style: italic;
//         &.please-wait {color:#333}
//         &.success {color:$touche_de_couleur}
//         &.error {color:red}
//     }
// }







